import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Gallery_ from 'react-grid-gallery';

import Classes from './EditCoverPhotoModal.module.scss';
import { useState } from 'react';
import { setCoverImg } from '../../../../store/actions/GalleryImages';

const EditCoverPhotoModal = props => {
    const dispatch = useDispatch();
    const [imageSelected, setImageSelectd] = useState({ idx: null, selected: false });
    const album = useSelector(state => state.galleryImages[props.album]);


    const selectImageHandler = idx => {
        setImageSelectd({ idx: idx, selected: true });
    };

    const setImageHandler = () => {
        const oldCoverImg = album.filter(img => img.isCover)[0];
        dispatch(setCoverImg(album[imageSelected.idx], props.album, oldCoverImg));
        props.onClose();
    };

    let galleryOrImage = <Gallery_
        images={album}
        backdropClosesModal={true}
        showLightboxThumbnails={true}
        onClickThumbnail={selectImageHandler}
    />;
    let title = <h1 style={{ color: 'white', textAlign: 'center' }}>Select cover image</h1>;
    if (imageSelected.selected) {
        title = <h1 style={{ color: 'white', textAlign: 'center' }}>Set this image as cover?</h1>
        galleryOrImage = (
            <div className={Classes.SelectedImgContainer}>
                <img src={album[imageSelected.idx].src} className={Classes.SelectedImg} />
                <div className={Classes.ButtonsContainer}>
                    <button className={Classes.ButtonSet} onClick={setImageHandler}>
                        Set
                    </button>
                    <button className={Classes.ButtonCancel} onClick={() => setImageSelectd({ idx: '', selected: false })}>
                        Cancel
                    </button>
                </div>
            </div>
        );
    }
    return (
        <div className={Classes.Backdrop} onClick={props.onClose}>
            <div className={Classes.Modal} onClick={e => e.stopPropagation()}>
                <hr />
                {title}
                <hr />
                {galleryOrImage}
            </div>
        </div>
    );
};

export default EditCoverPhotoModal;