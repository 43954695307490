import React from 'react';
import Gallery_ from 'react-grid-gallery';
import Heading3 from '../../../Headings/Heading3';
import { Link } from 'react-router-dom';
import Classes from './GalleryAlbumAuth.module.scss';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { removeImgs } from '../../../../store/actions/GalleryImages';
import AddImgsModal from '../AddImgsModal/AddImgsModal';
/*
albumTitle
album
*/

const GalleryAlbum = props => {
    const [showAddImgsModal, setShowAddImgsModal] = useState(false);
    const [anySelected, setAnySelected] = useState(false);
    const dispatch = useDispatch();
    const album = useSelector(state => state.galleryImages[props.albumTitle]);

    function selectImageHandler(idx) {
        album[idx].isSelected = !album[idx].isSelected;
        const selectedImgsArray = album.filter(img => img.isSelected);
        if (selectedImgsArray.length) {
            setAnySelected(true);
        } else {
            setAnySelected(false);
        }
    };
    const addImgsHandler = () => {
        setShowAddImgsModal(true);
    };

    const removeImgsHandler = () => {
        const selectedImgsArray = album.filter(img => img.isSelected);
        dispatch(removeImgs(selectedImgsArray, props.albumTitle));
    };

    let removeButton = null;
    if (anySelected) {
        removeButton = <button className={Classes.ButtonRemove} onClick={removeImgsHandler}>Remove</button>;
    }



    return (
        <div className={Classes.GalleryRoute}>
            {showAddImgsModal && <AddImgsModal onCloseModal={() => setShowAddImgsModal(false)} category={props.albumTitle} />}
            <div className={Classes.PathContainer}>
                <Link to={{ pathname: '/gallery' }} className={Classes.GalleryRouteHeading}>Gallery&nbsp;&rarr;&nbsp;</Link>
                <Heading3 extraClasses={Classes.GalleryRouteHeading}>Admin {props.albumTitle}</Heading3>
            </div>
            <div className={Classes.ButtonsContainer}>
                <button className={Classes.ButtonAdd} onClick={addImgsHandler}>Add</button>
                {removeButton}
            </div>
            <Gallery_
                images={album}
                backdropClosesModal={true}
                showLightboxThumbnails={true}
                onSelectImage={idx => selectImageHandler(idx)}
            />
        </div>
    );
};

export default GalleryAlbum;