const pillow1 = "https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Fpillows%2F115758782_218876912696864_64678817651505863_n.jpg?alt=media&token=69f3291a-4098-447e-8cae-e8ef548ea835";
const pillow2 = "https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Fpillows%2F115800362_334812654350135_3096247426301891960_n.jpg?alt=media&token=eab7df86-276d-42f8-ab25-14261f863688";
const pillow3 = "https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Fpillows%2F115804201_286884269059790_1434541825025409592_n.jpg?alt=media&token=7b0558d9-4df7-4fa6-9e82-f3765c0a6b7f";
const pillow4 = "https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Fpillows%2F115804399_291669621914536_5197970112554512835_n.jpg?alt=media&token=54b4621d-d237-4efb-a46b-3b1e3f43c0de";
const pillow5 = "https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Fpillows%2F115805543_288106469091901_6581196218779110902_n.jpg?alt=media&token=4ed7c0d8-e87e-4b3e-b6c8-43bb1a581d1e";
const pillow6 = "https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Fpillows%2F116133762_293893985186698_2877895237481903202_n.jpg?alt=media&token=4c802ea5-5e32-47a9-b7a6-e29bc72e5656";
const pillow7 = "https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Fpillows%2F116238592_736306467203766_8281322946148936599_n.jpg?alt=media&token=84e4752f-f024-4548-a4a0-7bace9c52582";
const pillow8 = "https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Fpillows%2F116336386_3490533184314721_3158141777091051968_n.jpg?alt=media&token=6de73239-36d5-4388-8b7e-df710730df07";
const pillow9 = "https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Fpillows%2F116367227_974972852949957_7300174309126474167_n.jpg?alt=media&token=c916648c-b619-47d5-9957-9103a85b3422";
const pillow10 = "https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Fpillows%2F116425802_1279098419088752_6492056408635280023_n.jpg?alt=media&token=8b955f5f-b4ca-4a75-8b60-885b8810a30c";
const pillow11 = "https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Fpillows%2F116582467_877253672797872_8179973891212230700_n.jpg?alt=media&token=8fd14be4-d88b-4e3c-9097-f6f1437a12a4";
const pillow12 = "https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Fpillows%2F116601462_1198907910472101_6333896379657151485_n.jpg?alt=media&token=18fbc1eb-6609-47d0-9142-5e40fa11be6b";
const pillow13 = "https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Fpillows%2F78604274_1464457687035902_19526931372310528_n.jpg?alt=media&token=50db37b9-1ed3-4718-96fa-24299fd02919";
const pillow14 = "https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Fpillows%2F84913466_211022963275575_3434181791760515072_n.jpg?alt=media&token=aa5f5973-283d-4d19-ae3c-30c20bbc92aa";
const pillow15 = 'https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Fpillows%2F66075128_2280008038749935_8110423036957556736_o.jpg?alt=media&token=d74fb325-b017-4619-a435-da5ebcc70580';
const pillow16 = 'https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Fpillows%2F60443306_2199040866846653_4242342977277526016_o.jpg?alt=media&token=0c5ef833-4a8f-41cb-bfd0-1f212f826a2b';
const pillow17 = 'https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Fpillows%2F66260798_2280007985416607_2223448734564352000_o.jpg?alt=media&token=873f6dd3-629d-4797-b836-d00ac7e39a57';
const pillow18 = 'https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Fpillows%2F48398279_1980083672075708_2637375709500145664_o.jpg?alt=media&token=3a0491b5-f367-4b39-9495-347a8d48b37c';
const pillow19 = 'https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Fpillows%2F48421081_1980083505409058_4936442943947931648_o.jpg?alt=media&token=08dbe1f1-13b1-4edf-8c9e-0021ad8bc77b';
const pillow20 = 'https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Fpillows%2F49432660_1989267704490638_3527647555796074496_o.jpg?alt=media&token=b7067659-737d-4794-a4ef-20785ade13cc';
const pillow21 = 'https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Fpillows%2F60070280_2181143718636368_9098939451240873984_o.jpg?alt=media&token=3296c0da-4131-44d5-af76-948cc5266d05';
const pillow22 = 'https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Fpillows%2F59774499_2181144308636309_8503583106282815488_o.jpg?alt=media&token=0d20e755-4b4f-4519-8ad8-76f053f995b2';
const pillow23 = 'https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Fpillows%2F60573321_2199039883513418_5686131366660407296_o.jpg?alt=media&token=f22bb745-dc0e-49cc-af5d-621823cd4c18';
const pillow24 = 'https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Fpillows%2F59918171_2181143711969702_1675737247799836672_o.jpg?alt=media&token=07efedd8-b644-45d1-9465-632a926bc4ea';
const pillow25 = 'https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Fpillows%2F78588678_2577384682345601_8294708366958133248_n.jpg?alt=media&token=b9fc4ba6-7663-43e4-a3da-8fd24da564b4';
const pillow26 = 'https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Fpillows%2F82209072_2669452779805457_2242888598359638016_n.jpg?alt=media&token=88deb777-6513-45b8-a203-84e9097cf967';
const pillow27 = 'https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Fpillows%2F60323873_2181143701969703_5911592758581133312_o.jpg?alt=media&token=e29044e5-46f0-41c0-ab6d-04d82453914c';
const pillow28 = 'https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Fpillows%2F57964050_2161714490579291_3193195811051667456_n.jpg?alt=media&token=cfaf995a-6674-47a0-9505-aa32e31fef3f';
const pillow29 = 'https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Fpillows%2F78485631_2577384462345623_3164402122666016768_n.jpg?alt=media&token=978f1120-c508-4ff2-968b-110614c51c71';
const pillow30 = 'https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Fpillows%2F83171807_2669452863138782_1313701333294907392_n.jpg?alt=media&token=7b1f97c2-353c-4a79-8e53-2e33aba5c620';
const pillow31 = 'https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Fpillows%2F49246590_1993262360757839_3505299878882312192_o.jpg?alt=media&token=7093f04e-2c7a-4a64-a36d-da0414c0c124';
const pillow32 = 'https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Fpillows%2F41637559_1853759708041439_6855183617379794944_n.jpg?alt=media&token=4272fe24-b33a-45aa-af6d-1faed8d86faf';
const pillow33 = 'https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Fpillows%2F58666105_2161714383912635_421049473821573120_n.jpg?alt=media&token=8cb2a1b4-afab-4ed0-94ba-9a948ff65e86';
const pillow34 = 'https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Fpillows%2F58619914_2161714437245963_7526967565340377088_n.jpg?alt=media&token=ffa5a738-fbfa-4d40-9780-629e4297de8d';
const pillow35 = 'https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Fpillows%2F58716083_2161714257245981_4656553609033416704_n.jpg?alt=media&token=34ae66db-5c6a-4713-bb90-73fe3f5d7418';


class Pillow {
    constructor(pillowsArray) {
        this.names = pillowsArray.map(img => img.imgName);
        this.urls = pillowsArray.map(img => img.imgUrl);
        this.covers = pillowsArray.map(img => img.isCover);
    }

    constructThumbnails = () => {
        return this.urls.map((url, idx) => {
            if (this.urls[idx] !== url) {
                throw new Error('Urls differ');
            }
            return {
                name: this.names[idx],
                isSelected: false,
                src: url,
                thumbnail: url,
                thumbnailWidth: 200,
                thumbnailHeight: 174,
                caption: "write something here",
                isCover: this.covers[idx]
            };
        });
    }

    static constructBackupThubmnails = () => {
        return [
            pillow1, pillow2, pillow3, pillow4, pillow5, pillow6, pillow7, pillow8, pillow9, pillow10,
            pillow11, pillow12, pillow13, pillow14, pillow15, pillow16, pillow17, pillow18, pillow19, pillow20,
            pillow21, pillow22, pillow23, pillow24, pillow25, pillow26, pillow27, pillow28, pillow29, pillow30,
            pillow31, pillow32, pillow33, pillow34, pillow35

        ].map((url, idx) => {
            return {
                name: false,
                isSelected: false,
                src: url,
                thumbnail: url,
                thumbnailWidth: 200,
                thumbnailHeight: 174,
                caption: "write something here",
                isCover: idx === 1 && true
            };
        });
    }
}

export default Pillow;
