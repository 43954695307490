import React from 'react';
import Gallery_ from 'react-grid-gallery';
import Heading3 from './Headings/Heading3';
import { Link } from 'react-router-dom';

/*
albumTitle
album
*/

import Classes from '../SASS/components/GalleryAlbum.module.scss';

const GalleryAlbum = props => {
    
    return (
        <div className={Classes.GalleryRoute}>
            <Link to={{pathname: '/gallery'}} className={Classes.GalleryRouteHeading}>Gallery&nbsp;&rarr;&nbsp;</Link>
            <Heading3 extraClasses={Classes.GalleryRouteHeading}>{props.albumTitle}</Heading3>
            <Gallery_
                images={props.album} backdropClosesModal={true}
                showLightboxThumbnails={true}
            />
        </div>
    );
};

export default GalleryAlbum;