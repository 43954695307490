import React from 'react';

import Classes from './FormTextArea.module.scss';
/*
PROPS
----
placeholder
id
label
extraClasses: optional
onChange
*/

const FormTextArea = props => {
    return (
        <React.Fragment>
            <textarea
                onChange={props.onChange}
                className={[Classes.TextArea, props.extraClasses].join(" ")}
                placeholder={props.placeholder}
                id={props.id}
                 />
            <label
                className={Classes.Label}
                htmlFor={props.id}
            >{props.label}</label>
        </React.Fragment>
    );
};

export default FormTextArea;