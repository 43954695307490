import React from 'react';

import Classes from '../SASS/components/UserLogin.module.scss';
import ButtonSecondaryClasses from '../SASS/components/Buttons/ButtonSecondary.module.scss';

import ButtonSecondary from './Buttons/ButtonSecondary';
import { useSelector, useDispatch } from 'react-redux';
import { changeLanguage } from '../store/actions/Language';

const UserLogin = props => {
    const language = useSelector(state => state.language.language);
    const dispatch = useDispatch();
    const changeLanguageHandler = language => {
        dispatch(changeLanguage(language));
    };

    return (
        <div className={Classes.UserLogin}>
            <ButtonSecondary
                title="GR"
                extraClasses={[ButtonSecondaryClasses.UserLoginSignUp, language === "GR" && ButtonSecondaryClasses.SelectedLanguage].join(" ")}
                onClick={changeLanguageHandler.bind(this, "GR")}
            />
            <ButtonSecondary
                title="EN"
                extraClasses={[ButtonSecondaryClasses.UserLoginLogin, language === "EN" && ButtonSecondaryClasses.SelectedLanguage].join(" ")}
                onClick={changeLanguageHandler.bind(this, "EN")}
            />
        </div>
    );
};

export default UserLogin;