import React from 'react';

import Classes from '../../SASS/components/Headings/Heading3.module.scss';

const Heading3 = props => {
    return (
        <h3 className={[Classes.Heading3, props.extraClasses].join(" ")}>
            {props.children}
        </h3>
    );
};

export default Heading3;