import React from 'react';

import Classes from '../SASS/components/CustomInput.module.scss';
/*
PROPS
----
type
placeholder
id
required
*/

const CustomInput = props => {
    let inputType = <input 
    onChange={props.onChange}
    type={props.type} 
    className={[Classes.Input, props.extraClasses].join(" ")} 
    placeholder={props.placeholder} 
    id={props.id} 
    required={props.required} />;
    if(props.isTextArea) {
        inputType = <textarea
        onChange={props.onChange}
        type={props.type} 
        className={[Classes.TextArea, props.extraClasses].join(" ")} 
        placeholder={props.placeholder} 
        id={props.id} 
        required={props.required} />;
    }
    return (
        <React.Fragment>
            {inputType}
            <label className={Classes.Label} htmlFor={props.id}>{props.label}</label>
        </React.Fragment>
    );
};

export default CustomInput;