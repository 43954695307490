import React from 'react';

import Classes from '../SASS/components/RadioButton.module.scss';

/*
PROPS
-----
id
name
children
extraClasses
*/

const RadioButton = props => {
    return (
        <div className={[Classes.RadioButton, props.extraClasses].join(" ")}>
            <input className={Classes.Input} type="radio" id={props.id} name={props.name} defaultChecked={props.checked} onChange={props.onChange}  />
            <label className={Classes.Label} htmlFor={props.id} name={props.name}>
                <span className={Classes.Span}></span>
                {props.children}
            </label>
        </div>
    );
};

export default RadioButton;