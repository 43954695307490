import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';

import Heading2 from './Headings/Heading2';
import Heading3 from './Headings/Heading3';
import CustomInput from '../components/CustomInput';

import Classes from '../SASS/components/ContactForm.module.scss';

import Heading2Classes from '../SASS/components/Headings/Heading2.module.scss';
import RadioButton from './RadioButton';
import ButtonSecondary from './Buttons/ButtonSecondary';
import SVGIcon from './SVGIcon';
import { useRef } from 'react';
import ValidErrorModal from './ValidErrorModal';
import { useEffect } from 'react';
import TEXTS from '../constants/TEXTS';
import URLs from '../constants/URLs';

const inputGroupGridColumn = { gridColumn: '1 / 3' };


const ContactForm = props => {
    const screenDimensions = useSelector(state => state.screenDimensions);
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [meansOfCommunication, setMeansOfCommunication] = useState('Either');
    const language = useSelector(state => state.language.language);

    const [listOfValidationErrors, setListOfValidationErrors] = useState([]);

    const [showValidErrModal, setShowValidErrModal] = useState(false);

    let mapWidth = screenDimensions.width / 5;
    let mapHeight = screenDimensions.width / 5;

    if (screenDimensions.width < 1200) {
        mapWidth = screenDimensions.width / 1.8;
        mapHeight = screenDimensions.width / 3;
    }
    let mapSnippet = <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d100881.75068637851!2d23.92427916201654!3d37.80047411428733!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMzfCsDQ4JzAxLjciTiAyM8KwNTknMzkuNSJF!5e0!3m2!1sen!2sgr!4v1607847368069!5m2!1sen!2sgr" height={mapHeight} width={mapWidth} frameBorder="0" style={{ border: 0 }} allowFullScreen="" aria-hidden="false" tabIndex="0" />
    const radioButtonsList = useRef();

    const isValid = () => {
        const errors = [];
        errors.push(fullName.replace(' ', '').length === 0);
        errors.push(email.replace(' ', '').length === 0);
        errors.push(phone.replace(' ', '').length === 0);
        errors.push(subject.replace(' ', '').length === 0);
        errors.push(message.replace(' ', '').length === 0);

        const validationList = [];
        for (let v = 0; v < errors.length; v++) {
            
            if (errors[v] && v === 0) { // error in full name
                validationList.push(language === 'EN' ? TEXTS.contactForm.fullNameEN : TEXTS.contactForm.fullNameGR);
            }
            if (errors[v] && v === 1) { // error in email
                validationList.push(language === 'EN' ? TEXTS.contactForm.emailEN : TEXTS.contactForm.emailGR);
            }
            if (errors[v] && v === 2) { // error in phone
                validationList.push(language === 'EN' ? TEXTS.contactForm.phoneEN : TEXTS.contactForm.phoneGR);
            }
            if (errors[v] && v === 3) { // error in subject
                validationList.push(language === 'EN' ? TEXTS.contactForm.subjectEN : TEXTS.contactForm.subjectGR);
            }
            if (errors[v] && v === 4) { // error in message
                validationList.push(language === 'EN' ? TEXTS.contactForm.subjectEN : TEXTS.contactForm.subjectGR);
            }
        }
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(email).toLowerCase())) {
            validationList.push('Enter a valid email address.');
        }
        if (validationList.length === 0) {
            return true;
        }
        setListOfValidationErrors(validationList);
    };
    useEffect(() => {
        if (listOfValidationErrors.length > 0) {
            setShowValidErrModal(true);
        }
    }, [listOfValidationErrors])


    const submitFormHandler = () => {
        if (isValid()) {
            setLoading(true);
            setSubmitted(false);
            setError(false);

            axios.post(`${URLs.SERVER}/api/send_email`, {
                fullName: fullName,
                email: email,
                phone: phone,
                subject: subject,
                message: message,
                meansOfCommunication: meansOfCommunication
            }).then(function (response) {
                setLoading(false);
                setSubmitted(true);
            })
                .catch(function (error) {
                    setLoading(false);
                    setSubmitted(false);
                    setError(true);
                });
        }
    }


    const fullNameHandler = (e) => {
        setFullName(e.target.value);
    };
    const emailHandler = (e) => {
        setEmail(e.target.value);
    };
    const phoneHandler = (e) => {
        setPhone(e.target.value);
    };
    const subjectHandler = (e) => {
        setSubject(e.target.value);
    };
    const messageHandler = (e) => {
        setMessage(e.target.value);
    };
    const meansOfCommunicationHandler = (e) => {
        setMeansOfCommunication(e.target.id.slice(5));
    };

    let contactUsFormOrSubmitted = (
        <React.Fragment>
            <Heading2 extraClasses={Heading2Classes.ContactForm}>{language === 'EN' ? TEXTS.contactForm.contactUsEN : TEXTS.contactForm.contactUsGR}</Heading2>
            <div className={Classes.InputGroup}>
                <CustomInput
                    onChange={fullNameHandler}
                    type="text"
                    placeholder={language === 'EN' ? TEXTS.contactForm.fullNameEN : TEXTS.contactForm.fullNameGR}
                    id="name"
                    required={true}
                    label={language === 'EN' ? TEXTS.contactForm.fullNameEN : TEXTS.contactForm.fullNameGR}
                />
            </div>

            <div className={Classes.InputGroup}>
                <CustomInput
                    onChange={emailHandler}
                    type="email"
                    placeholder={language === 'EN' ? TEXTS.contactForm.emailEN : TEXTS.contactForm.emailGR}
                    id="email"
                    required={true}
                    label={language === 'EN' ? TEXTS.contactForm.emailEN : TEXTS.contactForm.emailGR}
                />
            </div>

            <div className={Classes.InputGroup}>
                <CustomInput
                    onChange={phoneHandler}
                    type="phone"
                    placeholder={language === 'EN' ? TEXTS.contactForm.phoneEN : TEXTS.contactForm.phoneGR}
                    id="phone"
                    required={true}
                    label={language === 'EN' ? TEXTS.contactForm.phoneEN : TEXTS.contactForm.phoneGR}
                />
            </div>

            <div className={Classes.InputGroup}>
                <CustomInput
                    onChange={subjectHandler}
                    type="text"
                    placeholder={language === 'EN' ? TEXTS.contactForm.subjectEN : TEXTS.contactForm.subjectGR}
                    id="subject"
                    required={true}
                    label={language === 'EN' ? TEXTS.contactForm.subjectEN : TEXTS.contactForm.subjectGR}
                />
            </div>

            <div className={Classes.InputGroup} style={inputGroupGridColumn}>
                <CustomInput
                    onChange={messageHandler}
                    isTextArea={true}
                    type="text"
                    placeholder={language === 'EN' ? TEXTS.contactForm.yourMessageEN : TEXTS.contactForm.yourMessageGR}
                    id="message"
                    required={true}
                    label={language === 'EN' ? TEXTS.contactForm.yourMessageEN : TEXTS.contactForm.yourMessageGR}
                />
            </div>

            <div className={Classes.InputGroup} style={inputGroupGridColumn} ref={radioButtonsList}>
                <span className={Classes.MeansOfCommunicationText}>{language === 'EN' ? TEXTS.contactForm.pmocEN : TEXTS.contactForm.pmocGR}</span>
                <RadioButton
                    onChange={meansOfCommunicationHandler}
                    id="radioPhone"
                    name="contactMethod"
                >{language === 'EN' ? TEXTS.contactForm.phoneEN : TEXTS.contactForm.phoneGR}</RadioButton>
                <RadioButton
                    onChange={meansOfCommunicationHandler}
                    id="radioEmail"
                    name="contactMethod"
                >{language === 'EN' ? TEXTS.contactForm.emailEN : TEXTS.contactForm.emailGR}</RadioButton>
                <RadioButton
                    onChange={meansOfCommunicationHandler}
                    checked
                    id="radioEither"
                    name="contactMethod"
                >{language === 'EN' ? TEXTS.contactForm.eitherEN : TEXTS.contactForm.eitherGR}</RadioButton>
            </div>

            <div className={Classes.InputGroup} style={{ ...inputGroupGridColumn, textAlign: 'center' }}>
                <ButtonSecondary onClick={submitFormHandler} title={language === 'EN' ? TEXTS.contactForm.submitEN : TEXTS.contactForm.submitGR} extraClasses={Classes.ButtonSecondary} />
            </div>
        </React.Fragment>
    );

    if (error) {
        contactUsFormOrSubmitted = (
            <div className={Classes.SubmittedContainer}>
                <Heading3 extraClasses={Classes.MessageSentText}>Message Failed!</Heading3>
                <SVGIcon
                    extraClasses={Classes.SentIcon}
                    iconName="icon-mail-error"
                />
                <div className={Classes.InputGroup} style={{ textAlign: 'center' }}>
                    <ButtonSecondary onClick={() => {
                        setSubmitted(false);
                        setError(false);
                        setLoading(false);
                    }
                    }
                        title="Try Again" extraClasses={Classes.ButtonSecondary} />
                </div>
            </div>
        );
    }

    if (loading) {
        contactUsFormOrSubmitted = (
            <div className={Classes["lds-dual-ring"]}></div>
        );
    }
    if (submitted) {
        contactUsFormOrSubmitted = (
            <div className={Classes.SubmittedContainer}>
                <Heading3 extraClasses={Classes.MessageSentText}>Message Sent!</Heading3>
                <SVGIcon
                    extraClasses={Classes.SentIcon}
                    iconName="icon-mail-checked"
                />
                <div className={Classes.InputGroup} style={{ textAlign: 'center' }}>
                    <ButtonSecondary onClick={() => {
                        setSubmitted(false);
                        setError(false);
                        setLoading(false);
                    }} title="Send another" extraClasses={Classes.ButtonSecondary} />
                </div>
            </div>
        );
    }
    return (
        <div className={[Classes.ContactForm, props.extraClasses].join(" ")}>
            {showValidErrModal && <ValidErrorModal onCloseModal={() => setShowValidErrModal(false)} errorList={listOfValidationErrors} />}
            {contactUsFormOrSubmitted}
            <button className={Classes.ContactDetailsToggle}></button>
            <Heading3 extraClasses={Classes.Heading3ContantDetails}>{language === 'EN' ? TEXTS.contactForm.contactDetailsEN : TEXTS.contactForm.contactDetailsGR}</Heading3>
            <div className={Classes.Location}>
                {mapSnippet}
                <span className={Classes.ContactAddress}>{language === 'EN' ? TEXTS.contactForm.address1EN : TEXTS.contactForm.address1GR}<br />{language === 'EN' ? TEXTS.contactForm.address2EN : TEXTS.contactForm.address2GR}</span>
                <span className={Classes.ContactPhone}>698 6569757 / 693 4758859</span>
                <span className={Classes.ContactTelephone}>22990 69533</span>
                <span className={Classes.ContactEmail}>xyachtcovers@gmail.com</span>
                <span className={Classes.ContactFB}><a href="https://www.facebook.com/xyachtcovers" target="_blank">facebook.com/xyachtcovers</a></span>
            </div>
        </div >
    );
};

export default ContactForm;