import { TOGGLE_LANDING_PAGE } from '../actions/LandingPage';

const initialState = {
    showLandingPage: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case TOGGLE_LANDING_PAGE:
            const newState = !state.showLandingPage;
            return { showLandingPage: newState }
        default:
            return state;
    }
}
