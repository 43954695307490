import React from 'react';

import Classes from '../SASS/components/SVGIcon.module.scss';
import icons from '../static/img/SVG/sprite.svg';
/*
PROPS:
onClick=
extraClasses=
iconName=
*/

const SVGIcon = props => {
    return (
        <svg onClick={props.onClick} className={[Classes.SVGIcon, props.extraClasses].join(" ")} >
            <use xlinkHref={`${icons}#${props.iconName}`}/>
        </svg>
    );
};

export default SVGIcon;