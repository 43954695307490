import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import Classes from '../../SASS/containers/Content.module.scss';

import Home from '../../components/content/Home';
import About from '../../components/content/About';
import Gallery from '../../components/content/Gallery';
import GalleryAuth from '../../components/content/Authenticated/GalleryAuth/GalleryAuth';
import Services from '../../components/content/Services';
import Contact from '../../components/content/Contact';
import Feedback from '../../components/content/Feedback';
import { useSelector } from 'react-redux';

const Content = props => {
    const isAuth = useSelector(state => state.authentication.isAuth);

    return (
        <div className={Classes.Content}>
            <Switch>
                <Route path="/" exact>
                    <Redirect to="/home" />
                </Route>
                <Route path="/home" component={Home} />
                <Route path="/about" component={About} />
                <Route path="/contact" component={Contact} />
                <Route path="/services" component={Services} />
                <Route path="/gallery" component={Gallery} />
                {isAuth && <Route path="/galleryAuth" component={GalleryAuth} />}
                <Route path="/feedback" component={Feedback} />
            </Switch>
        </div >
    );
};

export default Content;