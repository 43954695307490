import React, { useState } from 'react';
import { Route, Link, Switch } from 'react-router-dom';
import Gallery_ from 'react-grid-gallery';
import AlbumItem from '../AlbumItem';

import GalleryAlbum from '../GalleryAlbum';

import Classes from '../../SASS/components/content/Gallery.module.scss';
import Heading3 from '../Headings/Heading3';
import TEXTS from '../../constants/TEXTS';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { fetchImgs } from '../../store/actions/GalleryImages';

/*
TO ADD NEW IMAGES GO INTO THE App.js file and comment in the call to the getGalleryImages() function,
to get the urls of the images stored in firebase. If you add more folders than just Pillows and Covers
you should also visit store/actions/GalleryImages.js and edit the file accordingly.
After you have access to the image Urls, go into the /gallery/<folder name>.js and add them there.
Last, go into the component you want the to load the images and just import the /gallery/<folder name>.js file
*/

const Gallery = props => {
    const language = useSelector(state => state.language.language);
    const albums = useSelector(state => state.galleryImages);

    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!albums['tents'].length) {
            setIsLoading(true);
            dispatch(fetchImgs());
        }
    }, [fetchImgs]);

    useEffect(() => {
        if (albums['tents'].length) {
            setIsLoading(false);
        }
    }, [albums]);

    const albumHandler = (albumId) => {
        props.history.push(`/gallery/${albumId}`)
    };

    let pillowsCoverImg, tentsCoverImg, coversCoverImg;
    let allPhotos = [];

    if (albums.pillows.length) {
        allPhotos = [...albums.pillows, ...albums.covers, ...albums.tents];
        pillowsCoverImg = albums.pillows.filter(img => img.isCover)[0].src;
        tentsCoverImg = albums.covers.filter(img => img.isCover)[0].src;
        coversCoverImg = albums.tents.filter(img => img.isCover)[0].src;
    }

    if (isLoading) {
        return (
            <div className={Classes.Gallery}
            style={{
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            
            }}
            >
                <div className={Classes.LoadingContainer}>
                    <div className="LoadingSpinner"></div>
                </div>
            </div>
        );
    }

    return (
        <div className={Classes.Gallery}>
            <Switch>
                <Route path="/gallery" exact>
                    <div className={Classes.GalleryInner}>
                        <div className={Classes.AlbumsContainerOutter}>
                            <Heading3 extraClasses={Classes.AlbumHeading}>{language === 'EN' ? TEXTS.gallery.albumEN : TEXTS.gallery.albumGR}</Heading3>
                            <div className={Classes.AlbumsContainerInner}>
                                <AlbumItem onClick={albumHandler.bind(this, 'pillows')} imgSrc={pillowsCoverImg} imgAlt="representative pillow" albumLabel={language === 'EN' ? TEXTS.gallery.album1EN : TEXTS.gallery.album1GR} itemsLength={albums.pillows.length} />
                                <AlbumItem onClick={albumHandler.bind(this, 'covers')} imgSrc={tentsCoverImg} imgAlt="representative cover" albumLabel={language === 'EN' ? TEXTS.gallery.album2EN : TEXTS.gallery.album2GR} itemsLength={albums.covers.length} />
                                <AlbumItem onClick={albumHandler.bind(this, 'tents')} imgSrc={coversCoverImg} imgAlt="representative tent" albumLabel={language === 'EN' ? TEXTS.gallery.album3EN : TEXTS.gallery.album3GR} itemsLength={albums.tents.length} />
                                {/* <AlbumItem onClick={albumHandler.bind(this, 'Other')} imgSrc={albums.Other[0].src} imgAlt="representative other" albumLabel="Other" itemsLength={OTHER.length} /> */}
                            </div>
                            {/* <Link to={{ pathname: '/gallery/albums' }} className={Classes.SeeMoreAlbum}>See All Albums</Link> */}
                        </div>
                        <div className={Classes.SeeAllPhotosContainer}>
                            <Heading3 extraClasses={Classes.AlbumHeading}>{language === 'EN' ? TEXTS.gallery.allPhotosEN : TEXTS.gallery.allPhotosGR}</Heading3>
                            <Gallery_
                                images={allPhotos}
                                backdropClosesModal={true}
                                showLightboxThumbnails={true}
                            />
                        </div>
                    </div>
                </Route>

                <Route path="/gallery/:albumId" render={props => <GalleryAlbum
                    albumTitle={props.match.params.albumId}
                    album={albums[props.match.params.albumId]} />}
                />
            </Switch>


        </div>
    );
};

export default Gallery;