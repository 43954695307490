import React, { useState } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import { IoIosStarOutline, IoIosStar } from 'react-icons/io';

import Classes from '../SASS/components/FiveStarReview.module.scss';


const FiveStarReview = props => {
    const [selectStar1, setSelectStar1] = useState(false);
    const [selectStar2, setSelectStar2] = useState(false);
    const [selectStar3, setSelectStar3] = useState(false);
    const [selectStar4, setSelectStar4] = useState(false);
    const [selectStar5, setSelectStar5] = useState(false);
    
    const star1Handler = () => {
        if (selectStar1) {
            if (selectStar2) {
                setSelectStar2(false);
                setSelectStar3(false);
                setSelectStar4(false);
                setSelectStar5(false);
                props.onReportBack(1)
            } else {
                setSelectStar1(false);
                setSelectStar2(false);
                setSelectStar3(false);
                setSelectStar4(false);
                setSelectStar5(false);
                props.onReportBack(0)
            }
        } else {
            setSelectStar1(true);
            props.onReportBack(1)
        }
    };

    const star2Handler = () => {
        if (selectStar2) {
            if (selectStar3) {
                setSelectStar3(false);
                setSelectStar4(false);
                setSelectStar5(false);
                props.onReportBack(2);
            } else {
                setSelectStar2(false);
                setSelectStar3(false);
                setSelectStar4(false);
                setSelectStar5(false);
                props.onReportBack(1);
            }
        } else {
            setSelectStar1(true);
            setSelectStar2(true);
            props.onReportBack(2);
        }
    };

    const star3Handler = () => {
        if (selectStar3) {
            if (selectStar4) {
                setSelectStar4(false);
                setSelectStar5(false);
                props.onReportBack(3);
            } else {
                setSelectStar3(false);
                setSelectStar4(false);
                setSelectStar5(false);
                props.onReportBack(2);
            }
        } else {
            setSelectStar1(true);
            setSelectStar2(true);
            setSelectStar3(true);
            props.onReportBack(3);
        }
    };

    const star4Handler = () => {
        if (selectStar4) {
            if (selectStar5) {
                props.onReportBack(4);
                setSelectStar5(false);
            } else {
                setSelectStar4(false);
                setSelectStar5(false);
                props.onReportBack(3);
            }
        } else {
            setSelectStar1(true);
            setSelectStar2(true);
            setSelectStar3(true);
            setSelectStar4(true);
            props.onReportBack(4);
        }
    };

    const star5Handler = () => {
        if (selectStar5) {
            setSelectStar5(false);
            props.onReportBack(4);
        } else {
            setSelectStar1(true);
            setSelectStar2(true)
            setSelectStar3(true);
            setSelectStar4(true);
            setSelectStar5(true);
            props.onReportBack(5);
        }
    };


    let star1 = <IoIosStarOutline className={Classes.Star1} onClick={star1Handler} />;
    if ((selectStar1 || selectStar2 || selectStar3 || selectStar4 || selectStar5)) {
        star1 = <IoIosStar className={[Classes.Star1, Classes.Selected].join(" ")} onClick={star1Handler} />
    }

    let star2 = <IoIosStarOutline className={Classes.Star2} onClick={star2Handler} />;
    if (selectStar2 || selectStar3 || selectStar4 || selectStar5) {
        star2 = <IoIosStar className={[Classes.Star2, Classes.Selected].join(" ")} onClick={star2Handler} />
    }

    let star3 = <IoIosStarOutline className={Classes.Star3} onClick={star3Handler} />;
    if (selectStar3 || selectStar4 || selectStar5) {
        star3 = <IoIosStar className={[Classes.Star3, Classes.Selected].join(" ")} onClick={star3Handler} />
    }

    let star4 = <IoIosStarOutline className={Classes.Star4} onClick={star4Handler} />;
    if (selectStar4 || selectStar5) {
        star4 = <IoIosStar className={[Classes.Star4, Classes.Selected].join(" ")} onClick={star4Handler} />
    }

    let star5 = <IoIosStarOutline className={Classes.Star5} onClick={star5Handler} />;
    if (selectStar5) {
        star5 = <IoIosStar className={[Classes.Star5, Classes.Selected].join(" ")} onClick={star5Handler} />
    }
    return (
        <div className={Classes.FiveStarReview}>
            {star1}
            {star2}
            {star3}
            {star4}
            {star5}
        </div>
    );
};

export default FiveStarReview;