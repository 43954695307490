import React from 'react';

import Heading3 from '../Headings/Heading3';

import Classes from '../../SASS/components/content/About.module.scss';
import TEXTS from '../../constants/TEXTS';
import { useSelector } from 'react-redux';

const About = props => {
    const language = useSelector(state => state.language.language);

    return (
        <div className={Classes.About}>
            <div className={Classes.ImgContainer}>
            </div>

            <Heading3 extraClasses={Classes.Heading3}>{language === 'EN' ? TEXTS.about.titleEN : TEXTS.about.titleGR}</Heading3>
            <p className={Classes.Paragraph}>{language === 'EN' ? TEXTS.about.textEN : TEXTS.about.textGR}</p>
        </div>
    );
};

export default About;