export default {
    header: {
        searchBoxPlaceholderEN: 'Search...',
        searchBoxPlaceholderGR: 'Αναζήτηση...'
    },
    home: {
        phraseEN: 'WORK WITH THE BEST',
        phraseGR: 'ΣΥΝΕΡΓΑΣΟΥ ΜΕ ΤΟΥΣ ΚΑΛΥΤΕΡΟΥΣ',
        subtitleEN: 'Dress your Yacht as you\'d dress yourself',
        subtitleGR: 'Ντύσε το σκάφος σου όπως θα ντυνόσουν εσύ',
        buttonEN: 'SEE OUR SERVICES',
        buttonGR: 'ΟΙ ΥΠΗΡΕΣΙΕΣ ΜΑΣ',
        subFooterEN: 'Partners',
        subFooterGR: 'Συνεργάτες'
    },
    navigation: {
        homeEN: 'HOME',
        homeGR: 'ΑΡΧΙΚΉ',
        aboutEN: 'ABOUT US',
        aboutGR: 'ΣΧΕΤΙΚΑ ΜΕ ΕΜΑΣ',
        servicesEN: 'SERVICES',
        servicesGR: 'ΥΠΗΡΕΣΙΕΣ',
        galleryEN: 'GALLERY',
        galleryGR: 'ΓΚΑΛΕΡΊ',
        contactEN: 'CONTACT',
        contactGR: 'ΕΠΙΚΟΙΝΩΝΙΑ',
        feedbackEN: 'FEEDBACK',
        feedbackGR: 'ΤΙ ΣΚΕΦΤΕΣΤΕ'
    },
    about: {
        titleEN: 'About our company',
        titleGR: 'Σχετικά με εμάς',
        textEN: 'Our company activities involve designing and repairing marine upholstery, seats, covers, and tents. Our several years of experience attest to our professionalism with which we address the needs of the interior and exterior areas of your boat. Using only the best products of the market we guarantee flawless fitting and long-lasting results. We strive to satisfy our customers keeping a balance between elegant solutions and cost.',
        textGR: 'Η εταιρία μας δραστηριοποιείται στον χώρο της κατασκευής και της επισκευής ταπετσαριών, μαξιλαριών, καλυμμάτων και τεντών σκαφών αναψυχής. Με πολυετή εμπειρία καλύπτουμε όλες τις ανάγκες στον εσωτερικό και εξωτερικό χώρο του σκάφους σας, χρησιμοποιώντας τα καλύτερα υλικά της αγοράς εγγυόμαστε αρίστη εφαρμογή και αντοχή στον χρόνο. Στόχος μας είναι η ικανοποίηση των πελατών μας βρίσκοντας πρακτικές λύσεις για κάθε κατασκευή σε λογικό κόστος.'
    },
    services: {
        mainTitleEN: 'Our Services',
        mainTitleGR: 'Οι υπηρεσίες μας',
        covers: {
            titleEN: 'COVERS',
            titleGR: 'ΚΑΛΥΜΜΑΤΑ',
            info1EN: 'Quality equals safety',
            info1GR: 'Η ποιότητα ισούται ασφάλεια',
            info2EN: 'Sun protection',
            info2GR: 'Προστασία από τον ήλιο',
            info3EN: 'Rain protection',
            info3GR: 'Προστασία από τη βροχή',
            info4EN: 'Long-lasting results',
            info4GR: 'Αποτελέσματα που αντέχουν'
        },
        tents: {
            titleEN: 'TENTS',
            titleGR: 'ΤΕΝΤΕΣ',
            info1EN: 'Every area of your boat',
            info1GR: 'Σε κάθε περιοχή του σκάφους σας',
            info2EN: 'Flawless fitting',
            info2GR: 'Αψεγάδιαστο ταίριασμα',
            info3EN: 'Excellent aesthetics',
            info3GR: 'Τέλεια αισθητική',
            info4EN: 'Enduring results',
            info4GR: 'Διαρκής αποτελέσματα'
        },
        pillows: {
            titleEN: 'PILLOWS',
            titleGR: 'ΜΑΞΙΛΑΡΙΑ',
            info1EN: 'Designing from scratch',
            info1GR: 'Σχεδίαση από το μηδέν',
            info2EN: 'Interior and exterior',
            info2GR: 'Εσωτερικά και εξωτερικά',
            info3EN: 'Top quality',
            info3GR: 'Άριστη ποιότητα',
            info4EN: 'Variety of leather',
            info4GR: 'Ποικιλία δερμάτων'
        }
    },
    gallery: {
        albumEN: 'Albums',
        albumGR: 'Άλμπουμ',
        album1EN: 'Pillows',
        album1GR: 'Μαξιλάρια',
        album2EN: 'Covers',
        album2GR: 'Καλύμματα',
        album3EN: 'Tents',
        album3GR: 'Τέντες',
        allPhotosEN: 'All photos',
        allPhotosGR: 'Όλες οι φωτογραφίες'
    },
    albumItemEN: 'items',
    albumItemGR: 'αρχεία',
    footer: {
        homeEN: 'HOME',
        homeGR: 'Αρχική',
        aboutEN: 'ABOUT US',
        aboutGR: 'Σχετικά με εμάς',
        servicesEN: 'SERVICES',
        servicesGR: 'Υπηρεσίες',
        galleryEN: 'GALLERY',
        galleryGR: 'Γκαλλερί',
        contactEN: 'CONTACT',
        contactGR: 'Επικοινωνία',
        feedbackEN: 'Feedback',
        feedbackGR: 'Τι σκέφτεστε'
    },
    contactForm: {
        contactUsEN: 'CONTACT US',
        contactUsGR: 'Επικοινωνήστε μαζί μας',
        contactDetailsEN: 'Contact details',
        contactDetailsGR: 'Στοιχεία',
        address1EN: 'Epar.Od. Kerateas-Anavissou',
        address1GR: 'Επαρ.Οδό Κερατέας-Αναβύσσου',
        address2EN: '19001 Keratéa, Attiki, Greece',
        address2GR: '19001 Κερατέα, Αττική, Ελλάδα',
        fullNameEN: 'Full name',
        fullNameGR: 'Ονοματεπώνυμο',
        emailEN: 'Email',
        emailGR: 'Email',
        phoneEN: 'Phone',
        phoneGR: 'Τηλέφωνο',
        subjectEN: 'Subject',
        subjectGR: 'Θέμα',
        yourMessageEN: 'Your message',
        yourMessageGR: 'Το μύνημά σας',
        pmocEN: 'Preferred means of communication',
        pmocGR: 'Προτεινόμενο μέσο επικοινωνίας',
        eitherEN: 'Either',
        eitherGR: 'Και τα δύο',
        submitEN: 'Submit',
        submitGR: 'Αποστολή'
    },

    feedback: {
        titleEN: 'Feebdack form',
        titleGR: 'Φορμα ανατροφοδοτησης',
        workedQEN: 'Have you worked with us?',
        workedQGR: 'Έχετε συνεργαστεί μαζί μας;',
        confidentiallityQEN: 'Do you want to tell us who you are?',
        confidentiallityQGR: 'Θέλετε να μας πείτε ποιος/ποια είστε;',
        answerYesEN: 'Yes',
        answerYesGR: 'Ναι',
        answerNoEN: 'No',
        answerNoGR: 'Όχι',
        nameEN: 'Full Name',
        namgeGR: 'Ονοματεπώνυμο',
        emailEN: 'Email',
        emailGR: 'Email',
        serviceQualityEN: 'How was xCover\'s service and quality?',
        serviceQualityGR: 'Πώς ήταν οι υπηρεσίες του xCover;',
        satisfacotryEN: 'Were the results satisfactory?',
        satisfacotryGR: 'Ήταν τα αποτελέσματα ικανοποιητικά;',
        friendlyEN: 'Were the employees friendly?',
        friendlyGR: 'Ήταν το προσωπικό φιλικό;',
        recommendEN: 'Would you recommend us to a friend?',
        recommendGR: 'Θα μας προτείνατε σε έναν φίλο;',
        otherCommentsEN: 'Other comments/improvement suggestions',
        otherCommentsGR: 'Άλλα σχόλια/προτάσεις βελτίωσης ',
        submitEN: 'Submit',
        submitGR: 'Αποστολή',
        sentEN: 'Feedback Sent!',
        sentGR: 'Η ανατροφοδότηση στάλθηκε!'
    }
}