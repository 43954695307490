import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Gallery_ from 'react-grid-gallery';
import AlbumItemAuth from '../AlbumItemAuth/AlbumItemAuth';

import GalleryAlbumAuth from '../GalleryAlbumAuth/GalleryAlbumAuth';
import EditCoverPhotoModal from '../EditCoverPhotoModal/EditCoverPhotoModal';

import Classes from './GalleryAuth.module.scss';
import Heading3 from '../../../Headings/Heading3';
import TEXTS from '../../../../constants/TEXTS';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { fetchImgs } from '../../../../store/actions/GalleryImages';
import { useState } from 'react';

const GalleryAuth = props => {
    const [editCoverPhotoModal, setEditCoverPhotoModal] = useState({ display: false, album: '' });
    const language = useSelector(state => state.language.language);
    const albums = useSelector(state => state.galleryImages);

    const dispatch = useDispatch();
    useEffect(() => {
        if (!albums['tents'].length) {
            dispatch(fetchImgs());
        }
    }, [fetchImgs]);

    const albumHandler = (albumId) => {
        props.history.push(`/galleryAuth/${albumId}`)
    };

    const editCoverPhotoHandler = (albumId) => {
        setEditCoverPhotoModal({ display: true, album: albumId });
    };

    let pillowsCoverImg, tentsCoverImg, coversCoverImg;
    let allPhotos = [];

    if (albums.pillows.length) {
        allPhotos = [...albums.pillows, ...albums.covers, ...albums.tents];
        pillowsCoverImg = albums.pillows.filter(img => img.isCover)[0].src;
        tentsCoverImg = albums.covers.filter(img => img.isCover)[0].src;
        coversCoverImg = albums.tents.filter(img => img.isCover)[0].src;
    }

    return (
        <div className={Classes.Gallery}>
            { editCoverPhotoModal.display && <EditCoverPhotoModal album={editCoverPhotoModal.album} onClose={() => setEditCoverPhotoModal({ display: false, album: '' })} />}
            <Switch>
                <Route path="/galleryAuth" exact>
                    <div className={Classes.GalleryInner}>
                        <div className={Classes.AlbumsContainerOutter}>
                            <Heading3 extraClasses={Classes.AlbumHeading}>{language === 'EN' ? TEXTS.gallery.albumEN : TEXTS.gallery.albumGR}</Heading3>
                            <div className={Classes.AlbumsContainerInner}>
                                <AlbumItemAuth
                                    onEditCoverPhoto={editCoverPhotoHandler.bind(this, 'pillows')}
                                    onClick={albumHandler.bind(this, 'pillows')}
                                    imgSrc={pillowsCoverImg}
                                    imgAlt="representative pillow"
                                    albumLabel={language === 'EN' ? TEXTS.gallery.album1EN : TEXTS.gallery.album1GR} itemsLength={albums.pillows.length} />
                                <AlbumItemAuth
                                    onEditCoverPhoto={editCoverPhotoHandler.bind(this, 'covers')}
                                    onClick={albumHandler.bind(this, 'covers')}
                                    imgSrc={tentsCoverImg}
                                    imgAlt="representative cover"
                                    albumLabel={language === 'EN' ? TEXTS.gallery.album2EN : TEXTS.gallery.album2GR} itemsLength={albums.covers.length} />
                                <AlbumItemAuth
                                    onEditCoverPhoto={editCoverPhotoHandler.bind(this, 'tents')}
                                    onClick={albumHandler.bind(this, 'tents')}
                                    imgSrc={coversCoverImg}
                                    imgAlt="representative tent"
                                    albumLabel={language === 'EN' ? TEXTS.gallery.album3EN : TEXTS.gallery.album3GR} itemsLength={albums.tents.length} />
                                {/* <AlbumItemAuth onClick={albumHandler.bind(this, 'Other')} imgSrc={albums.Other[0].src} imgAlt="representative other" albumLabel="Other" itemsLength={OTHER.length} /> */}
                            </div>
                            {/* <Link to={{ pathname: '/gallery/albums' }} className={Classes.SeeMoreAlbum}>See All Albums</Link> */}
                        </div>
                        <div className={Classes.SeeAllPhotosContainer}>
                            <Heading3 extraClasses={Classes.AlbumHeading}>{language === 'EN' ? TEXTS.gallery.allPhotosEN : TEXTS.gallery.allPhotosGR}</Heading3>
                            <Gallery_
                                images={allPhotos}
                                backdropClosesModal={true}
                                showLightboxThumbnails={true}
                            />
                        </div>
                    </div>
                </Route>

                <Route path="/galleryAuth/:albumId" exact render={props => <GalleryAlbumAuth
                    albumTitle={props.match.params.albumId}
                />
                }
                />

            </Switch>


        </div>
    );
};

export default GalleryAuth;