import React from 'react';

import Classes from '../../SASS/components/Headings/Heading1.module.scss';

const Heading1 = props => {
    return (
        <h1 className={[Classes.Heading1, props.extraClasses].join(" ")}>
            {props.children}
        </h1>
    );
};

export default Heading1;