
export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';


export const loginUser = () => {
    localStorage.setItem('isAuth', true);
    return { type: LOGIN_USER };
}

export const logoutUser = () => {
    localStorage.removeItem('isAuth');
    return { type: LOGOUT_USER };
}


export const authCheckState = () => {
    const isAuth = localStorage.getItem('isAuth');
    if (isAuth) {
        return { type: LOGIN_USER };
    } else {
        return { type: LOGOUT_USER };
    }
};  