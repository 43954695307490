import { CHANGE_LANGUAGE } from '../actions/Language';

const initialState = {
    language: 'EN'
};

export default (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_LANGUAGE:
            return { language: action.language };
        default:
            return state;
    }
};