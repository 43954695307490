const tent1 = 'https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Ftents%2F116054999_285239239203219_1123578843934209105_n.jpg?alt=media&token=5d165062-7d96-4d16-9d6b-29af8efae24c';
const tent2 = 'https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Ftents%2F116294109_706476920209429_2844404307723518634_n.jpg?alt=media&token=2807a9e1-1d67-4402-9ea7-501aa13b85f2';
const tent3 = 'https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Ftents%2F116420309_771139830324278_8100020935166610144_n.jpg?alt=media&token=c44fdffb-294d-425c-844e-ff40477cd7b9';
const tent4 = 'https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Ftents%2F116640394_2599290813656962_7810263569737841524_n.jpg?alt=media&token=cf06a528-aa3a-4efb-972a-330419a4ddb3';
const tent5 = 'https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Ftents%2F27536649_541169262929708_930295340_o.jpg?alt=media&token=71f47c4c-8da5-4648-8038-f49c60080e2e';
const tent6 = 'https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Ftents%2F41632281_1853744328042977_3468671955400392704_o.jpg?alt=media&token=41d84c3b-fa14-4c90-8390-cad62999e196';
const tent7 = 'https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Ftents%2F48378812_1980098108740931_3290388051921993728_o.jpg?alt=media&token=98a990be-963b-40be-bb77-935b1fe7ec5f';
const tent8 = 'https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Ftents%2F48389953_1980108925406516_134528245545566208_o.jpg?alt=media&token=b6a91021-16c2-4d59-bcc6-b8c8c33a4005';
const tent9 = 'https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Ftents%2F48392904_1980108822073193_7775934020486955008_o.jpg?alt=media&token=891582fd-26df-4966-b1b0-647344bc8333';
const tent10 = 'https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Ftents%2F48424154_1980108578739884_4859805931224956928_o.jpg?alt=media&token=3376ed3c-20ac-4c28-bb8e-9bcc74a99e01';
const tent11 = 'https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Ftents%2F60065584_2199050856845654_3728447932697935872_o.jpg?alt=media&token=9c32d6b7-7436-41b6-90df-634bf2a3644b';
const tent12 = 'https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Ftents%2F60731407_2199039973513409_7794404888120655872_o.jpg?alt=media&token=55f40a4a-27ff-40d4-8b51-80ec0aa8375c';
const tent13 = 'https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Ftents%2F60843806_2199051173512289_8543392466659704832_o.jpg?alt=media&token=9d72df0e-caae-4e28-b83f-48a6d42a4980';
const tent14 = 'https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Ftents%2F61283823_2216380478446025_5767431077484298240_o.jpg?alt=media&token=bbec9f18-8d6d-4651-b2e7-6c5547483bcb';
const tent15 = 'https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Ftents%2F61625804_2216382038445869_1509958970532429824_o.jpg?alt=media&token=863cf0f9-8aa0-4d02-ac18-9db478d4eded';
const tent16 = 'https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Ftents%2F61763652_2216374631779943_7085686145559822336_o.jpg?alt=media&token=fdc30e13-579f-4660-be66-47c59861c73c';
const tent17 = 'https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Ftents%2F81916260_2654361414647927_7115525009941987328_n.jpg?alt=media&token=ab2a5ace-7723-4770-94db-1148b9885fb6';
const tent18 = 'https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Ftents%2F82054261_2654361594647909_1361484107183292416_n.jpg?alt=media&token=31a66f4d-11ce-414f-a373-43b5b9026cd1';
const tent19 = 'https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Ftents%2F82447088_2654361714647897_5428190439909883904_n.jpg?alt=media&token=3d65357b-0a04-43e6-b9d6-439f9dae9fe2';


class Tent {
    constructor(tentsArray) {
        this.names = tentsArray.map(img => img.imgName);
        this.urls = tentsArray.map(img => img.imgUrl);
        this.covers = tentsArray.map(img => img.isCover);
    }

    constructThumbnails = () => {
        return this.urls.map((url, idx) => {
            if (this.urls[idx] !== url) {
                throw new Error('Urls differ');
            }
            return {
                name: this.names[idx],
                isSelected: false,
                src: url,
                thumbnail: url,
                thumbnailWidth: 200,
                thumbnailHeight: 174,
                caption: "write something here",
                isCover: this.covers[idx]
            };
        });
    }

    static constructBackupThubmnails = () => {
        return [
            tent1, tent2, tent3, tent4, tent5, tent6, tent7, tent8, tent9, tent10,
            tent11, tent12, tent13, tent14, tent15, tent16, tent17, tent18, tent19
        ].map((url, idx) => {
            return {
                name: false,
                isSelected: false,
                src: url,
                thumbnail: url,
                thumbnailWidth: 200,
                thumbnailHeight: 174,
                caption: "write something here",
                isCover: idx === 1 && true
            };
        });
    }
}

export default Tent;
