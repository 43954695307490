import React from 'react';

import Classes from '../../SASS/components/content/Services.module.scss';

import Heading3 from '../Headings/Heading3';
import FlippingCard from '../FlippingCard';
import TEXTS from '../../constants/TEXTS';
import { useSelector } from 'react-redux';

const Services = props => {
    const language = useSelector(state => state.language.language);
    return (
        <div className={Classes.Services}>
            <Heading3 extraClasses={Classes.Heading3}>{language === 'EN' ? TEXTS.services.mainTitleEN : TEXTS.services.mainTitleGR}</Heading3>
            <div className={Classes.CardsContainer}>
                <FlippingCard 
                    cardNum={1}
                    extraClasses={Classes.Card1}
                    detailsList={language === 'EN' ?  
                    [TEXTS.services.covers.info1EN, TEXTS.services.covers.info2EN, TEXTS.services.covers.info3EN, TEXTS.services.covers.info4EN] : 
                    [TEXTS.services.covers.info1GR, TEXTS.services.covers.info2GR, TEXTS.services.covers.info3GR, TEXTS.services.covers.info4GR]}
                    heading={language === 'EN' ? TEXTS.services.covers.titleEN : TEXTS.services.covers.titleGR}
                    onClickAlbum={() => props.history.push('/gallery/covers')}
                />
                <FlippingCard 
                    cardNum={2}
                    extraClasses={Classes.Card2}
                    detailsList={language === 'EN' ?  
                    [TEXTS.services.tents.info1EN, TEXTS.services.tents.info2EN, TEXTS.services.tents.info3EN, TEXTS.services.tents.info4EN] : 
                    [TEXTS.services.tents.info1GR, TEXTS.services.tents.info2GR, TEXTS.services.tents.info3GR, TEXTS.services.tents.info4GR]}
                    heading={language === 'EN' ? TEXTS.services.tents.titleEN : TEXTS.services.tents.titleGR}
                    onClickAlbum={() => props.history.push('/gallery/tents')}
                />
                <FlippingCard 
                    cardNum={3}
                    extraClasses={Classes.Card3}
                    detailsList={language === 'EN' ?  
                    [TEXTS.services.pillows.info1EN, TEXTS.services.pillows.info2EN, TEXTS.services.pillows.info3EN, TEXTS.services.pillows.info4EN] : 
                    [TEXTS.services.pillows.info1GR, TEXTS.services.pillows.info2GR, TEXTS.services.pillows.info3GR, TEXTS.services.pillows.info4GR]}
                    heading={language === 'EN' ? TEXTS.services.pillows.titleEN : TEXTS.services.pillows.titleGR}
                    onClickAlbum={() => props.history.push('/gallery/pillows')}
                />
            </div>
        </div>
    );
};

export default Services;