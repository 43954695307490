import React, { useState, useEffect, useCallback } from 'react';

import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Transition } from 'react-transition-group'; // ES6

import Classes from '../SASS/containers/Layout.module.scss';

import { screenSizeChange } from '../store/actions/ScreenSize';

import LandingPage from '../components/LandingPage/LandingPage';
import Header from '../Layout/containers/Header';
import Navigation from '../Layout/containers/Navigation';
import Content from '../Layout/containers/Content';
import Footer from '../Layout/containers/Footer';
import { authCheckState } from '../store/actions/Authentication';



const Layout = props => {
    const [bgColor, setBgColor] = useState(null);
    const dispatch = useDispatch();
    const screenDimensions = useSelector(state => state.screenDimensions);
    const getScreenDimensions = useCallback(() => {
        const screenDimensions = {
            width: "",
            height: ""
        };

        screenDimensions.height = window.innerHeight;
        screenDimensions.width = window.innerWidth;
        dispatch(screenSizeChange(screenDimensions));
    }, []);
    useEffect(() => {
        dispatch(authCheckState());
    }, [dispatch])

    useEffect(() => {
        window.onresize = getScreenDimensions;
    }, [getScreenDimensions]);

    const showLandingPage = useSelector(state => state.landingPage.showLandingPage);


    let layoutWidth;
    if (screenDimensions.width > 1700) {
        layoutWidth = 'LayoutMargin';
    }

    let landingPage = <LandingPage />;
    if (!showLandingPage) {
        landingPage = null;
    }
    return (
        <Transition
            in={showLandingPage && (props.location.pathname === '/' || props.location.pathname === '/home')}
            timeout={1400}>
            {state => state !== 'exited' && (props.location.pathname === '/' || props.location.pathname === '/home') ? <LandingPage transitionStyle={state} /> : (
                <div className={[Classes.Layout, Classes[bgColor], Classes[layoutWidth]].join(" ")}>
                    <Header />
                    <Navigation />
                    <Content />
                    <Footer />
                </div>
            )}
        </Transition>
    );
};
export default withRouter(Layout);