import React from 'react';

import Classes from '../SASS/components/ErrorModal.module.scss';
import ButtonSecondary from './Buttons/ButtonSecondary';

const ErrorModal = props => {
    return (
        <div className={Classes.ErrorModal} onClick={props.onCloseModal}>
            <div className={Classes.InnerContainer} onClick={(e) => {e.stopPropagation()}}>
                <h2 className={Classes.Error}>ERROR!</h2>
                <p className={Classes.Msg}>This functionality is not ready yet!</p>
                <p className={Classes.Emoji}>&#128534;</p>
                <ButtonSecondary onClick={props.onCloseModal} extraClasses={Classes.Btn} title="Go back" />
            </div>
        </div>
    );
};

export default ErrorModal;