import React from 'react';
import { Link } from 'react-router-dom';

import Classes from '../../SASS/containers/Footer.module.scss';
import logo from '../../static/img/SVG/SVG/favicon-large-color-primary-medium.svg';
import { useSelector } from 'react-redux';
import TEXTS from '../../constants/TEXTS';

const Footer = props => {
    const language = useSelector(state => state.language.language);

    return (
        <div className={Classes.Footer}>
            <div className={Classes.SocialMedia}>
                <a href="https://www.facebook.com/xyachtcovers" target="_blank" className={["fa", "fa-facebook", Classes.Fa, Classes.FaFacebook].join(" ")}></a>
            </div>

            <div className={Classes.LinksContainer}>
                <Link to="/home" className={Classes.Link}>{language === 'EN' ? TEXTS.footer.homeEN : TEXTS.footer.homeGR}</Link>
                <Link to="/about" className={Classes.Link}>{language === 'EN' ? TEXTS.footer.aboutEN : TEXTS.footer.aboutGR}</Link>
                <Link to="/services" className={Classes.Link}>{language === 'EN' ? TEXTS.footer.servicesEN : TEXTS.footer.servicesGR}</Link>
                <Link to="/gallery" className={Classes.Link}>{language === 'EN' ? TEXTS.footer.galleryEN : TEXTS.footer.galleryGR}</Link>
                <Link to="/contact" className={Classes.Link}>{language === 'EN' ? TEXTS.footer.contactEN : TEXTS.footer.contactGR}</Link>
                <Link to="/feedback" className={Classes.Link}>{language === 'EN' ? TEXTS.footer.feedbackEN : TEXTS.footer.feedbackGR}</Link>
            </div>

            <p className={Classes.Copyrights}>Copyright &copy;<br/>2017-{new Date().getFullYear()} XCOVERS | All rights Reserved. </p>
        </div>
    );
};

export default Footer;