import { LOGIN_USER, LOGOUT_USER } from '../actions/Authentication';

const initialState = {
    isAuth: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_USER:
            return { isAuth: true};
        case LOGOUT_USER:
            return { isAuth: false};
        default:
            return state;
    }
};

