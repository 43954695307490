import React from 'react';

import Classes from '../../SASS/components/Headings/Heading2.module.scss';

const Heading2 = props => {
    return (
        <h2 className={[Classes.Heading2, props.extraClasses].join(" ")}>
            {props.children}
        </h2>
    );
};

export default Heading2;