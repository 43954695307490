import firebase from 'firebase';
import Pillow from '../../gallery/PILLOWS';
import Cover from '../../gallery/COVERS';
import Tent from '../../gallery/TENTS';

export const FETCH_IMGS = 'FETCH_IMGS';
export const REMOVE_IMGS = 'REMOVE_IMGS';
export const SET_COVER_IMG = 'SET_COVER_IMG'
export const ADD_IMGS = 'ADD_IMGS';
export const UPDATE_STATUS = 'UPDATE_STATUS';
export const REMOVE_STATUS = 'REMOVE_STATUS';
export const FETCH_IMGS_FROM_ONE_CATEGORY = 'FETCH_IMGS_FROM_ONE_CATEGORY';

export const addImgs = (imgs, names, category) => {
    return dispatch => {
        names.map((name, idx) => {
            const uploadImageTask = firebase.storage().ref().child("gallery/" + category + "/" + name).put(imgs[idx]);
            uploadImageTask.on('state_changed', snapshot => {
                const percentage = snapshot.bytesTransferred / snapshot.totalBytes * 100;
                dispatch({ type: UPDATE_STATUS, imgName: name, percentage: percentage });
            }, err => {
                console.log(err, 'GalleryImages', '21');
            }, () => {
                console.log('success', 'GalleryImages', '24');
                fetchImagesFromCategory(category)
                    .then(imgsData => {
                        const imgs = imgsData.imgNames.map((imgName, idx) => {
                            return { imgName: imgName, imgUrl: imgsData.imgUrls[idx], isCover: imgsData.isCover[idx] };
                        })
                        switch (category) {
                            case 'tents':
                                const tents = new Tent(imgs).constructThumbnails();
                                dispatch({
                                    type: FETCH_IMGS_FROM_ONE_CATEGORY,
                                    imgs: tents,
                                    category: category
                                });
                                break;
                            case 'pillows':
                                const pillows = new Pillow(imgs).constructThumbnails();
                                dispatch({
                                    type: FETCH_IMGS_FROM_ONE_CATEGORY,
                                    imgs: pillows,
                                    category: category
                                });
                                break;
                            case 'covers':
                                const covers = new Cover(imgs).constructThumbnails();
                                dispatch({
                                    type: FETCH_IMGS_FROM_ONE_CATEGORY,
                                    imgs: covers,
                                    category: category
                                });
                                break;
                            default:
                                throw new Error('Wrong category name');
                        }
                        dispatch({ type: REMOVE_STATUS, imgName: name });
                    })
                    .catch(err => console.log(err));
            });
        });
        // dispatch({ type: ADD_IMGS, imgs: imgs, category: category });
    }
};

export const removeImgs = (imgs, category) => {
    const names = imgs.map(img => img.name);
    const imgUrls = imgs.map(img => img.src);
    return dispatch => {
        const ref = firebase.storage().ref().child("gallery/" + category);
        const allDeletePromises = [];
        names.forEach(name => {
            allDeletePromises.push(ref.child(name).delete());
        });
        Promise.all(allDeletePromises)
            .then(() => {
                dispatch({ type: REMOVE_IMGS, imgUrls: imgUrls, category: category });
            })
            .catch(err => console.log(err));
    }
};




export const setCoverImg = (img, category, oldCoverImg) => {
    return dispatch => {
        // delete metadata from previous cover
        const oldRef = firebase.storage().ref().child("gallery/" + category + "/" + oldCoverImg.name);
        const deleteMetadata = {
            customMetadata: null
        };
        oldRef.updateMetadata(deleteMetadata)
            .then()
            .catch(err => console.log(err));
        const ref = firebase.storage().ref().child("gallery/" + category + "/" + img.name);
        const newMetadata = {
            customMetadata: {
                'isCover': 'true'
            }
        };
        ref.updateMetadata(newMetadata)
            .then(metadata => {
                dispatch({ type: SET_COVER_IMG, newCoverImg: img, category: category, oldCoverImg: oldCoverImg })
            })
            .catch(err => console.log(err));
    }
};



const fetchImagesFromCategory = category => {
    const allPromises = [];
    const allNamePromises = [];
    let urls;
    return new Promise((resolve, reject) => {
        const ref = firebase.storage().ref().child("gallery/" + category);
        // const ref = firebase.storage().ref().child("images/");
        ref.listAll()
            .then(data => {
                data.items.forEach(imageRef => {
                    allPromises.push(imageRef.getDownloadURL());
                    allNamePromises.push(imageRef.getMetadata());
                });
                return Promise.all(allPromises);
            })
            .then(fetchedUrls => {
                urls = fetchedUrls;
                return Promise.all(allNamePromises);
            })
            .then(metaDatas => {
                const names = metaDatas.map(file => file.name);
                const isCoverArray = metaDatas.map(file => file.customMetadata).map(f => typeof (f) !== 'undefined');
                resolve({ imgNames: names, imgUrls: urls, isCover: isCoverArray });
            })
            .catch(err => {
                reject();
                console.log(err);
            });
    });
};



export const fetchImgs = () => {
    return dispatch => {
        let tentImgs;
        let pillowImgs;
        let coverImgs;
        fetchImagesFromCategory('tents')
            .then(tentImgsData => {
                tentImgs = tentImgsData.imgNames.map((imgName, idx) => {
                    return { imgName: imgName, imgUrl: tentImgsData.imgUrls[idx], isCover: tentImgsData.isCover[idx] };
                });
                return fetchImagesFromCategory('pillows');
            })
            .then(pillowImgsData => {
                pillowImgs = pillowImgsData.imgNames.map((imgName, idx) => {
                    return { imgName: imgName, imgUrl: pillowImgsData.imgUrls[idx], isCover: pillowImgsData.isCover[idx] };
                });
                return fetchImagesFromCategory('covers');
            })
            .then(coverImgsData => {
                coverImgs = coverImgsData.imgNames.map((imgName, idx) => {
                    return { imgName: imgName, imgUrl: coverImgsData.imgUrls[idx], isCover: coverImgsData.isCover[idx] };
                });
                dispatch({
                    type: FETCH_IMGS,
                    tents: new Tent(tentImgs).constructThumbnails(),
                    pillows: new Pillow(pillowImgs).constructThumbnails(),
                    covers: new Cover(coverImgs).constructThumbnails()
                });
            })
            .catch(err => {
                console.log(err);
                dispatch({
                    type: FETCH_IMGS,
                    tents: Tent.constructBackupThubmnails(),
                    pillows: Pillow.constructBackupThubmnails(),
                    covers: Cover.constructBackupThubmnails()
                });
            });
    };
};
