import React from 'react';

import Classes from '../SASS/components/BgVideo.module.scss';

const BgVideo = props => {
    return (
        <div className={[Classes.BgVideo, props.extraClasses].join(" ")}>
            <video className={Classes.Video} autoPlay webkit-playsinline playsInline muted loop>
                {props.videoList}
                Your browser is not supported!
            </video>
        </div>
    );
};

export default BgVideo;