import React from 'react';

import Classes from '../SASS/components/FlippingCard.module.scss';
import ButtonSecondary from './Buttons/ButtonSecondary';
import { useSelector } from 'react-redux';

/*
PROPS
------
props.cardNum
props.detailsList
props.heading
props.price

*/
const FlippingCard = props => {
    const language = useSelector(state => state.language.language);

    let cardNumberClass = '';
    let cardPictureClass = '';
    let HeadingSpan = '';
    switch (props.cardNum) {
        case 1:
            cardNumberClass = 'Card1';
            cardPictureClass = 'CardPicture1';
            HeadingSpan = 'Span1';
            break;
        case 2:
            cardNumberClass = 'Card2';
            cardPictureClass = 'CardPicture2';
            HeadingSpan = 'Span2';
            break;
        case 3:
            cardNumberClass = 'Card3';
            cardPictureClass = 'CardPicture3';
            HeadingSpan = 'Span3';
            break;
        default:
            alert(`Wrong card number:${props.cardNum} given from within Tours.js file`);
            break;
    }

    const detailsList = props.detailsList.map((element, idx) => {
        return <li key={idx}>{element}</li>;
    });

    return (
        <div className={[Classes.FlippingCard, props.extraClasses].join(" ")}>
            {/* FRONT SIDE */}
            <div className={[Classes.Side, Classes.FrontSide, Classes[cardNumberClass]].join(" ")}>
                <div className={[Classes.CardPicture, Classes[cardPictureClass]].join(" ")}>
                    &nbsp;
                </div>
                <h4 className={Classes.CardHeading}>
                    <span className={[Classes.HeadingSpan, Classes[HeadingSpan]].join(" ")}>
                        {props.heading}
                    </span>
                </h4>
                <div className={Classes.CardDetails}>
                    <ul>
                        {detailsList}
                    </ul>
                </div>
            </div>
            {/* BACK SIDE */}
            <div className={[Classes.Side, Classes.BackSide, Classes[cardNumberClass]].join(" ")}>
                <div className={Classes.CTA}>
                    <ButtonSecondary onClick={props.onClickAlbum} extraClasses={Classes.ButtonSecondary} title={language === 'EN' ? 'See Album' : 'Φωτογραφίες'} />
                </div>
            </div>
        </div>
    );
};

export default FlippingCard;