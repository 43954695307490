import React, { useState } from 'react';

import Classes from '../../SASS/containers/Header.module.scss';
import SVGClasses from '../../SASS/components/SVGIcon.module.scss';

import UserLogin from '../../components/UserLogin';
import SVGIcon from '../../components/SVGIcon';
import ErrorModal from '../../components/ErrorModal';
import TEXTS from '../../constants/TEXTS';
import { useSelector } from 'react-redux';
import LogoWhole from '../../components/LogoWhole';

const Header = props => {
    const [showSearchModal, setShowSearchModal] = useState(false);
    const language = useSelector(state => state.language.language);

    const searchHandler = () => {
        setShowSearchModal(true);
    };
    const closeModalHandler = () => {
        setShowSearchModal(false);
    };

    return (
        <header className={Classes.Header}>
            {showSearchModal && <ErrorModal onCloseModal={closeModalHandler} />}
            <LogoWhole color="primaryMedium" />

            <div className={Classes.SearchBoxContainer}>
                <form className={Classes.SearchBoxForm} onSubmit={(e) => {
                    e.preventDefault();
                    searchHandler();
                }}>
                    <input placeholder={language === "EN" ? TEXTS.header.searchBoxPlaceholderEN : TEXTS.header.searchBoxPlaceholderGR} className={Classes.SearchBox} />
                    <SVGIcon onClick={searchHandler} iconName="icon-magnifying-glass" extraClasses={SVGClasses.Header} />
                </form>
            </div>
            <UserLogin />
        </header>
    );
};

export default Header;