import React from 'react';

import Classes from '../../SASS/components/Buttons/ButtonSecondary.module.scss';

const ButtonSecondary = props => {
    return (
        <button onClick={props.onClick} className={[Classes.ButtonSecondary, props.extraClasses].join(" ")}>
            {props.title}
        </button>
    );
};

export default ButtonSecondary;