import React from 'react';
/*
    extraClasses
    imgSrc
    imgAlt
    albumLabel
    itemsLength
    onClick
*/
import Heading3 from './Headings/Heading3';

import Classes from '../SASS/components/AlbumItem.module.scss';
import { useSelector } from 'react-redux';
import TEXTS from '../constants/TEXTS';

const AlbumItem = props => {
    const language = useSelector(state => state.language.language);
    return (
        <div className={[Classes.AlbumItem, props.extraClasses].join(" ")}>
            <img onClick={props.onClick} className={Classes.RepImg} src={props.imgSrc} alt={props.imgAlt} />
            <Heading3 extraClasses={Classes.AlbumItemHeading}>{props.albumLabel}</Heading3>
            <span>{props.itemsLength} {language === 'EN' ? TEXTS.albumItemEN :TEXTS.albumItemGR}</span>
        </div>
    );
};

export default AlbumItem;