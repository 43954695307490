import { FETCH_IMGS, REMOVE_IMGS, SET_COVER_IMG, FETCH_IMGS_FROM_ONE_CATEGORY, UPDATE_STATUS, REMOVE_STATUS } from '../actions/GalleryImages';

const initialState = {
    tents: [],
    pillows: [],
    covers: [],
    uploadingImgs: []
};

const deleteImgsRecur = (imgUrls, imgArray) => {
    imgArray = imgArray.filter(img => img.src !== imgUrls[0]);
    imgUrls.shift();
    if (!imgUrls.length) {
        return imgArray;
    } else {
        return deleteImgsRecur(imgUrls, imgArray);
    }
};

export default (state = initialState, action) => {
    switch (action.type) {

        case FETCH_IMGS_FROM_ONE_CATEGORY:
            return { ...state, [action.category]: action.imgs };

        case FETCH_IMGS:
            return { ...state, tents: action.tents, pillows: action.pillows, covers: action.covers };

        case REMOVE_IMGS:
            const oldImgs = [...state[action.category]];
            // a for loop that keeps only the images that are not selected and delete the others
            const updatedImgs = deleteImgsRecur(action.imgUrls, oldImgs);

            return { ...state, [action.category]: updatedImgs };

        case SET_COVER_IMG:
            const updatedCover = [...state[action.category]];
            updatedCover.forEach((img, idx) => {
                if (img.name === action.oldCoverImg.name) {
                    img.isCover = false;
                }
                if (img.name === action.newCoverImg.name) {
                    img.isCover = true;
                }
            });
            return { ...state, [action.category]: updatedCover };

        case UPDATE_STATUS:
            const updatedStatus = [...state.uploadingImgs];

            // checking if there is an img with the same name in the array
            if (!updatedStatus.filter(img => img.imgName === action.imgName).length) {
                // if there is no image
                updatedStatus.push({ imgName: action.imgName, percentage: action.percentage });
            } else {
                // if there is an image
                updatedStatus.forEach((img, idx) => {
                    if (img.imgName === action.imgName) {
                        img.percentage = Math.round(action.percentage);
                    }
                });
            }
            return { ...state, uploadingImgs: updatedStatus }

        case REMOVE_STATUS:
            const removedStatus = [...state.uploadingImgs];
            removedStatus.filter(img => img.imgName !== action.imgName);
            return { ...state, uploadingImgs: removedStatus };

        default:
            return state;
    }
};