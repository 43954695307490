// steps to connect to firebase
/*
0. Create a Firebase project and in it a firebase storage.

1. Create this file with the api key detaisl
2. Initialise the connection in the App.js file using the following lines

  if(!firebase.apps.length) {
    firebase.initializeApp(ApiKeys.FirebaseConfig);
    firebase.analytics();
}
*/
export default {
    firebaseConfig: {
        apiKey: "AIzaSyCUE5pHB5rQEpZWuxyn7bof2jY9lPfZe8c",
        authDomain: "xcovers-1780f.firebaseapp.com",
        databaseURL: "https://xcovers-1780f.firebaseio.com",
        projectId: "xcovers-1780f",
        storageBucket: "xcovers-1780f.appspot.com",
        messagingSenderId: "964969366801",
        appId: "1:964969366801:web:8223b03c064c85f645f8b0",
        measurementId: "G-LHP1GWSM7P"
    }
};