import React from 'react';

import ContactForm from '../ContactForm';
import BgVideo from '../BgVideo';

import Classes from '../../SASS/components/content/Contact.module.scss';
import BgVideoClasses from '../../SASS/components/BgVideo.module.scss';
import ContactFormClasses from '../../SASS/components/ContactForm.module.scss';

import video1 from '../../static/img/contact/video.mp4';

const Contact = props => {
    const videoList = [
        {src: video1, type: 'video/mp4'}
    ].map((video, idx) => <source key={idx} src={video.src} type={video.type} />);

    return (
        <div className={Classes.Contact}>
            <BgVideo videoList={videoList} extraClasses={BgVideoClasses.Contact}/>
            <ContactForm extraClasses={ContactFormClasses.Contact}/>
        </div>
    );
};

export default Contact;