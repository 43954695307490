import React from 'react';
import ReduxThunk from 'redux-thunk';
import { createStore, combineReducers, applyMiddleware } from 'redux'; //applyMiddleware
import { Provider } from 'react-redux';
// REDUCERS
import landingPageReducer from './store/reducers/LandingPage';
import screenDimensionsReducer from './store/reducers/ScreenSize';
import galleryImagesReducer from './store/reducers/GalleryImages';
import authenticationReducer from './store/reducers/Authentication';

import Layout from './Layout/Layout';
import languageReducer from './store/reducers/Language';

// firebase
import firebase from 'firebase';
import ApiKeys from './constants/ApiKeys';

if (!firebase.apps.length) {
  firebase.initializeApp(ApiKeys.firebaseConfig);
  firebase.analytics();
}


const rootReducer = combineReducers({
  landingPage: landingPageReducer,
  screenDimensions: screenDimensionsReducer,
  language: languageReducer,
  galleryImages: galleryImagesReducer,
  authentication: authenticationReducer
});

const store = createStore(rootReducer, applyMiddleware(ReduxThunk));

function App() {

  return (
    <Provider store={store}>
      <Layout />
    </Provider>
  );
}

export default App;
