const cover1 = 'https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Fcovers%2F116268499_284768142618549_7941180810193235714_n.jpg?alt=media&token=ac84b596-4e37-43bf-b645-f0906402bc64';
const cover2 = 'https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Fcovers%2F20170922_180223.jpg?alt=media&token=849d2b5f-a9df-450b-a9f0-274448dbeb25';
const cover3 = 'https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Fcovers%2F20170922_180310.jpg?alt=media&token=6cef1aa2-47ed-4bbc-a52d-01044407c093';
const cover4 = 'https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Fcovers%2F20181011_184643.jpg?alt=media&token=e8793bb1-2c64-4813-b5b4-38580ef87893';
const cover5 = 'https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Fcovers%2F12%20(14).jpg?alt=media&token=a2659665-e3ed-45d0-a7e5-e80b703e63a9';
const cover6 = 'https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Fcovers%2F12%20(3).jpg?alt=media&token=49c8b95d-1275-4d88-a0ea-49492f948a73';
const cover7 = 'https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Fcovers%2F12%20(5).jpg?alt=media&token=6c068883-d04b-429e-93eb-e7f5728c6dce';
const cover8 = 'https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Fcovers%2F49442060_1997650266985715_354425760737394688_o.jpg?alt=media&token=0ed5ba25-8fb0-449c-9416-d9b528f7709d';
const cover9 = 'https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Fcovers%2F51190854_2039686046115470_7875031865332072448_o.jpg?alt=media&token=56abdfe0-3f41-4110-b027-0cf19ac8197a';
const cover10 = 'https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Fcovers%2F51378891_2039686216115453_2250119832932450304_o.jpg?alt=media&token=a7768f3b-3891-4a04-af01-253704171a32';
const cover11 = 'https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Fcovers%2F61923762_2216381631779243_2975331421708615680_o.jpg?alt=media&token=74052305-d5e9-4ed7-8d6f-5581559a2d70';
const cover12 = 'https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Fcovers%2F87614925_2751657714918296_4612897035996102656_n.jpg?alt=media&token=36ab3cd8-d04c-4c61-99a4-22bff99432d6';
const cover13 = 'https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Fcovers%2F95258697_2891281314289268_3066953948989489152_n.jpg?alt=media&token=5ebf9e24-c640-45a4-9882-ceedba414be4';
const cover14 = 'https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Fcovers%2F95370179_2891281134289286_2762304329476997120_n.jpg?alt=media&token=85e6740b-e66b-4cdf-a5e3-4a151ee4c748';



class Cover {
    constructor(coversArray) {
        this.names = coversArray.map(img => img.imgName);
        this.urls = coversArray.map(img => img.imgUrl);
        this.covers = coversArray.map(img => img.isCover);
    }

    constructThumbnails = () => {
        return this.urls.map((url, idx) => {
            if (this.urls[idx] !== url) {
                throw new Error('Urls differ');
            }

            return {
                name: this.names[idx],
                isSelected: false,
                src: url,
                thumbnail: url,
                thumbnailWidth: 200,
                thumbnailHeight: 174,
                caption: "write something here",
                isCover: this.covers[idx]
            };
        });
    }

    static constructBackupThubmnails = () => {
        return [
            cover1, cover2, cover3, cover4, cover5, cover6, cover7, cover8, cover9, cover10,
            cover11, cover12, cover13, cover14

        ].map((url, idx) => {
            return {
                name: false,
                isSelected: false,
                src: url,
                thumbnail: url,
                thumbnailWidth: 200,
                thumbnailHeight: 174,
                caption: "write something here",
                isCover: idx === 0 && true
            };
        });
    }
}

export default Cover;