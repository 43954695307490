import { SCREEN_SIZE_CHANGE } from '../actions/ScreenSize';


const initialState = {
    width: window.innerWidth,
    height: window.innerHeight
};


export default (state = initialState, action) => {
    switch (action.type) {
        case SCREEN_SIZE_CHANGE:
            return action.dimensions;
        default:
            return state;
    }
};
