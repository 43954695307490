import React from 'react';

import Classes from './AddImgsModal.module.scss';
import generateToken from '../../../../utils/randomIdGenerator';
import { addImgs } from '../../../../store/actions/GalleryImages';
import { useDispatch, useSelector } from 'react-redux';

const AddImgsModal = props => {
    const uploadingImgs = useSelector(state => state.galleryImages.uploadingImgs);
    const dispatch = useDispatch();
    const selectFilesHandler = e => {
        const names = Object.values(e.target.files).map(file => generateToken(10));
        dispatch(addImgs(e.target.files, names, props.category));
    };

    let uploadingList;
    if (uploadingImgs.length) {
        uploadingList = uploadingImgs.map((img, idx) => {
            return (
                <div key={idx} style={{backgroundColor: 'white'}}>
                    {''.repeat(100-img.percentage)}{'|'.repeat(img.percentage)}{img.percentage}%
                </div>
            );
        });
    }

    return (
        <div className={Classes.Backdrop} onClick={props.onCloseModal}>
            <div className={Classes.Modal} onClick={e => e.stopPropagation()}>
                {uploadingList}
                <div className={Classes.UploadContainer}>
                    <input className={Classes.ChooseFile} multiple type="file" name="file" onChange={selectFilesHandler} />
                </div>
            </div>
        </div>
    );
};

export default AddImgsModal;