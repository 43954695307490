import React from 'react';

import Classes from './FormSmallInput.module.scss';

/*
PROPS
----
placeholder
id
onChange
extraClasses: optional
label
*/

const FormSmallInput = props => {
    return (
        <React.Fragment>
            <input
                className={[Classes.Input, props.extraClasses].join(" ")}
                onChange={props.onChange}
                placeholder={props.placeholder}
                id={props.id}
            />
            <label
                className={Classes.Label}
                htmlFor={props.id}
            >{props.label}</label>
        </React.Fragment>
    );
};

export default FormSmallInput;