import React from 'react';

import Classes from '../../SASS/components/content/Home.module.scss';
import Heading2Classes from '../../SASS/components/Headings/Heading2.module.scss';
import ButtonSecondaryClasses from '../../SASS/components/Buttons/ButtonSecondary.module.scss';
import bottomMarginClasses from '../../SASS/components/MarginBottom.module.scss';

import ButtonSecondary from '../Buttons/ButtonSecondary';
import Heading2 from '../Headings/Heading2';
import Heading3 from '../Headings/Heading3';
import TEXTS from '../../constants/TEXTS';
import { useSelector } from 'react-redux';
import LogoWhole from '../LogoWhole';

const Home = props => {
    const servicesButtonHandler = () => {
        props.history.push('/services');
    };

    const language = useSelector(state => state.language.language);
    return (
        <div className={Classes.Home}>
            <div className={[Classes.TitleContainer, bottomMarginClasses.Huge].join(" ")}>
                <LogoWhole color="white" withGoldenLogo />
            </div>
            <span className={Classes.Text}>{language === 'EN' ? TEXTS.home.phraseEN : TEXTS.home.phraseGR}</span>
            <Heading3 extraClasses={Heading2Classes.Home}>{language === 'EN' ? TEXTS.home.subtitleEN : TEXTS.home.subtitleGR}</Heading3>
            <ButtonSecondary onClick={servicesButtonHandler} extraClasses={[ButtonSecondaryClasses.Home, bottomMarginClasses.Huge].join(" ")} title={language === 'EN' ? TEXTS.home.buttonEN : TEXTS.home.buttonGR} />
            {/* <span className={Classes.SeenText}>{language === 'EN' ? TEXTS.home.subFooterEN : TEXTS.home.subFooterGR}</span>
            <div className={Classes.CooperatorsContainer}>
                <img src={coopImg1} alt="coop1" className={Classes.CoopsImg1} />
                <img src={coopImg2} alt="coop2" className={Classes.CoopsImg2} />
                <img src={coopImg3} alt="coop3" className={Classes.CoopsImg3} />
                <img src={coopImg4} alt="coop4" className={Classes.CoopsImg4} />
            </div> */}
        </div>
    );
};

export default Home;