import React from 'react';

import logoNeedlePrimaryMedium from '../static/img/SVG/SVG/logo-needle-color-primary-medium.svg';
import logoXPrimaryMedium from '../static/img/SVG/SVG/favicon-large-color-primary-medium.svg';

import logoNeedleWhite from '../static/img/SVG/SVG/logo-needle-color-white.svg';
import logoXWhite from '../static/img/SVG/SVG/favicon-large-color-white.svg';

import Classes from '../SASS/components/LogoWhole.module.scss';
import { useState } from 'react';
import LoginForm from './LoginForm.js/LoginForm';

/*
Available colors
primaryMedium
white
*/
const LogoWhole = props => {
    const [showLogin, setShowLogin] = useState(false);

    let logoX;
    let logoNeedle;
    if (props.color === "primaryMedium") {
        logoX = logoXPrimaryMedium;
        logoNeedle = logoNeedlePrimaryMedium;
    } else if (props.color === "white") {
        logoX = logoXWhite;
        logoNeedle = logoNeedleWhite;
    }

    const onGoldenLogo = (e) => {
        e.stopPropagation();
    }

    return (
        <React.Fragment>
            {showLogin && <LoginForm onClick={() => setShowLogin(false)} />}
            <div className={[Classes.LogoContainer, Classes[props.color]].join(" ")} onClick={() => setShowLogin(oldState => !oldState)}>
                <object data={logoX} className={[Classes.LogoX, Classes[props.color]].join(" ")} alt="logo-x" />
                <p className={[Classes.CoverText, Classes[props.color]].join(" ")}>COVERS</p>
                <object data={logoNeedle} className={[Classes.LogoNeedle, Classes[props.color]].join(" ")} alt="logo-needle" />
                <p className={[Classes.AllAboutText, Classes[props.color]].join(" ")}>All about yachts</p>
                {props.withGoldenLogo && <a href="https://www.xrysietairia.eu/company/x-covers-398204" target="_blank" className={Classes.NavGoldenLogo} onClick={onGoldenLogo}>
                    <img src="https://www.xrysietairia.eu/company-image/398204-white.png" alt="X Covers - Κερατέα" title="X Covers - Κερατέα" />
                </a>}
            </div>
        </React.Fragment>
    );
};

export default LogoWhole;