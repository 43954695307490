import React from 'react';
import axios from 'axios';
import Classes from '../../SASS/components/content/Feedback.module.scss';
import Heading2Classes from '../../SASS/components/Headings/Heading2.module.scss';
import SVGIcon from '../SVGIcon';
import Heading3 from '../Headings/Heading3';
import ButtonSecondary from '../Buttons/ButtonSecondary';
import Heading2 from '../Headings/Heading2';
import TEXTS from '../../constants/TEXTS';
import { useSelector } from 'react-redux';
import FormRadioButton from '../FormRadioButton/FormRadioButton';
import FormSmallInput from '../FormSmallInput/FormSmallInput';
import FormTextArea from '../FormTextArea/FormTextArea';
import FiveStarReview from '../FiveStarReview';
import { useState } from 'react';
import URLs from '../../constants/URLs';


const Feedback = props => {
    const [feedbackSent, setFeedbackSent] = useState(false);
    const [confidentiallity, setConfidentiallity] = useState(null);
    const [workedWithUs, setWorkedWithUs] = useState(null);

    const [serviceQualRating, setServiceQualRating] = useState(0);
    const [resultsRating, setResultsRating] = useState(0);
    const [friendlyRating, setFriendlyRating] = useState(0);
    const [recommendRating, setRecommendRating] = useState(0);

    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [comment, setComment] = useState('');

    const language = useSelector(state => state.language.language);

    let classesType; // work/confidentiallity
    if (workedWithUs && confidentiallity) {
        classesType = "YesYes";
    } else if (workedWithUs === false && confidentiallity === false) {
        classesType = "ΝοΝο";
    } else if (workedWithUs === false && confidentiallity === null) {
        classesType = "NoNull";
    } else if (workedWithUs === null && confidentiallity === false) {
        classesType = "NullNo";
    } else if (workedWithUs === true && confidentiallity === null) {
        classesType = "YesNull";
    } else if (workedWithUs === null && confidentiallity === true) {
        classesType = "NullYes";
    } else if (workedWithUs === false && confidentiallity === true) {
        classesType = "NoYes";
    } else if (workedWithUs === true && confidentiallity === false) {
        classesType = "YesNo";
    } else {
        classesType = "NullNull";
    }

    const reportBack = (category, rating) => {
        switch (category) {
            case 'CompanyServiceAndQuality':
                setServiceQualRating(rating);
                break;
            case 'results':
                setResultsRating(rating);
                break
            case 'friendly':
                setFriendlyRating(rating);
                break
            case 'recommend':
                setRecommendRating(rating);
                break
            default:
                break;
        }
    };

    const confidentialityHandler = e => {
        setConfidentiallity(e);
    };

    const workHandler = e => {
        setWorkedWithUs(e);
    };

    const nameHandler = e => {
        setFullName(e.target.value);
    };

    const emailHandler = e => {
        setEmail(e.target.value);
    };

    const commentHandler = e => {
        setComment(e.target.value);
    };


    const submitHandler = () => {
        const feedback = {
            confidentiallity,
            workedWithUs,
            serviceQualRating,
            resultsRating,
            friendlyRating,
            recommendRating,
            fullName,
            email,
            comment
        };
        axios.post(`${URLs.SERVER}/api/send_feedback`, feedback).then(response => {
            setFeedbackSent(true);
        })
            .catch(function (error) {
                throw error;
            });
    };

    let feedbackFormORsubmitted = (
        <React.Fragment>
            <Heading2 extraClasses={Heading2Classes.FeedbackForm}>{language === 'EN' ? TEXTS.feedback.titleEN : TEXTS.feedback.titleGR}</Heading2>

            <div className={Classes.WorkedQuestion}>
                <p className={Classes.RadioButtonTitle}>{language === 'EN' ? TEXTS.feedback.workedQEN : TEXTS.feedback.workedQGR}</p>
                <div style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, 1fr)',
                    columnGap: '1rem'
                }}>
                    <FormRadioButton
                        id='workedYes'
                        name='worked?'
                        onChange={workHandler.bind(this, true)}
                    >
                        {language === 'EN' ? TEXTS.feedback.answerYesEN : TEXTS.feedback.answerYesGR}
                        </FormRadioButton>
                    <FormRadioButton
                        id='workedNo'
                        name='worked?'
                        onChange={workHandler.bind(this, false)}
                    >
                        {language === 'EN' ? TEXTS.feedback.answerNoEN : TEXTS.feedback.answerNoGR}
                        </FormRadioButton>
                </div>
            </div>

            <div className={Classes.Confidentiality}>
                <p className={Classes.RadioButtonTitle}>{language === 'EN' ? TEXTS.feedback.confidentiallityQEN : TEXTS.feedback.confidentiallityQGR}</p>
                <div style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, 1fr)',
                    columnGap: '1rem'
                }}>
                    <FormRadioButton
                        id='confidentialityYes'
                        name='confidentiality'
                        onChange={confidentialityHandler.bind(this, true)}
                    >
                        {language === 'EN' ? TEXTS.feedback.answerYesEN : TEXTS.feedback.answerYesGR}
                    </FormRadioButton>
                    <FormRadioButton
                        id='confidentialityNo'
                        name='confidentiality'
                        onChange={confidentialityHandler.bind(this, false)}
                    >
                        {language === 'EN' ? TEXTS.feedback.answerNoEN : TEXTS.feedback.answerNoGR}
                    </FormRadioButton>
                </div>
            </div>


            {
                confidentiallity && (
                    <div className={Classes.NameContainer}>
                        <FormSmallInput
                            placeholder= {language === 'EN' ? TEXTS.feedback.nameEN : TEXTS.feedback.namgeGR}
                            id='name'
                            onChange={nameHandler}
                            label='Full Name'
                        />
                    </div>
                )
            }

            {
                confidentiallity && (
                    <div className={Classes.EmailContainer}>
                        <FormSmallInput
                            placeholder={language === 'EN' ? TEXTS.feedback.emailEN : TEXTS.feedback.emailGR}
                            id='email'
                            onChange={emailHandler}
                            label='Email'
                        />
                    </div>
                )
            }


            {
                (workedWithUs && confidentiallity !== null) && (
                    <React.Fragment>
                        <div className={Classes.ServiceRating}>
                            <p className={Classes.RadioButtonTitle}>
                            {language === 'EN' ? TEXTS.feedback.serviceQualityEN : TEXTS.feedback.serviceQualityGR}
                            </p>
                            <FiveStarReview onReportBack={rating => reportBack('CompanyServiceAndQuality', rating)} />
                        </div>
                        <div className={Classes.SatisfactionRating}>
                            <p className={Classes.RadioButtonTitle}>
                            {language === 'EN' ? TEXTS.feedback.satisfacotryEN : TEXTS.feedback.satisfacotryGR}
                            </p>
                            <FiveStarReview onReportBack={rating => reportBack('results', rating)} />
                        </div>
                        <div className={Classes.FriendlyRating}>
                            <p className={Classes.RadioButtonTitle}>
                            {language === 'EN' ? TEXTS.feedback.friendlyEN : TEXTS.feedback.friendlyGR}
                            </p>
                            <FiveStarReview onReportBack={rating => reportBack('friendly', rating)} />
                        </div>
                        <div className={Classes.ReccomendRating}>
                            <p className={Classes.RadioButtonTitle}>
                            {language === 'EN' ? TEXTS.feedback.recommendEN : TEXTS.feedback.recommendGR}
                            </p>
                            <FiveStarReview onReportBack={rating => reportBack('recommend', rating)} />
                        </div>
                    </React.Fragment>
                )
            }


            {
                ((workedWithUs !== null && confidentiallity !== null) &&
                    <div className={Classes.Comment}>
                        <FormTextArea
                            placeholder={language === 'EN' ? TEXTS.feedback.otherCommentsEN : TEXTS.feedback.otherCommentsGR}
                            id='comment'
                            label='Other comments'
                            onChange={commentHandler}
                        />
                    </div>
                )
            }

            {
                ((workedWithUs !== null && confidentiallity !== null) &&
                    <ButtonSecondary
                        onClick={submitHandler}
                        title={language === 'EN' ? TEXTS.feedback.submitEN : TEXTS.feedback.submitGR}
                        extraClasses={Classes.ButtonSecondary} />
                )
            }
        </React.Fragment>
    );
    if (feedbackSent) {
        feedbackFormORsubmitted = (
            <div className={Classes.SubmittedContainer}>
                <Heading3 extraClasses={Classes.MessageSentText}>{language === 'EN' ? TEXTS.feedback.sentEN : TEXTS.feedback.sentGR}</Heading3>
                <SVGIcon
                    extraClasses={Classes.SentIcon}
                    iconName="icon-mail-checked"
                />
            </div>
        );
    }
    return (
        <div className={Classes.Feedback}>
            <div className={[Classes.FeedbackForm, Classes[classesType]].join(" ")}>
                {feedbackFormORsubmitted}
            </div>
        </div>
    );
};

export default Feedback;