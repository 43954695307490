import React from 'react';

import Classes from '../../SASS/components/Buttons/NavButton.module.scss';
import { useSelector } from 'react-redux';

const NavButton = props => {
    const language = useSelector(state => state.language.language);
    const screenDimensions = useSelector(state => state.screenDimensions);

    let greekFont = '';
    if (language === 'GR' && screenDimensions.width < 400 && screenDimensions.height > screenDimensions.width) {
        greekFont = 'FontSizeGreek';
    }


    return (
        <button onClick={props.onClick} className={[Classes.NavButton, props.extraClasses, Classes[greekFont]].join(" ")}>
            {props.title}
        </button>
    );
};

export default NavButton;