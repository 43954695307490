import React from 'react';
import { useDispatch } from 'react-redux';
import { toggleLandingPage } from '../../store/actions/LandingPage';

import Classes from '../../SASS/components/LandingPage/LandingPage.module.scss';
import Heading1Classes from '../../SASS/components/Headings/Heading1.module.scss';
import Heading2Classes from '../../SASS/components/Headings/Heading2.module.scss';
import ButtonPrimaryClasses from '../../SASS/components/Buttons/ButtonPrimary.module.scss';
import MarginBottomClasses from '../../SASS/components/MarginBottom.module.scss';
import SVGIconClasses from '../../SASS/components/SVGIcon.module.scss';

import logo from '../../static/img/logo/logo-white.png'
import Heading1 from '../Headings/Heading1';
import Heading2 from '../Headings/Heading2';
import ButtonPrimary from '../Buttons/ButtonPrimary';
import SVGIcon from '../SVGIcon';

const LandingPage = props => {

    const dispatch = useDispatch();
    const onWelcomeHandler = () => {
        dispatch(toggleLandingPage());
    };

    const ButtonSymbol = <SVGIcon iconName="icon-sailing-boat-water" extraClasses={SVGIconClasses.LandingPage} />;
    let BackgroundTransClasses = 'Background';
    let LandingPageTransClasses = 'LandingPage';
    let HeadersContainerTransClasses = 'HeadersContainer';
    let ContentTransClasses = 'Content';

    if (props.transitionStyle === 'exited') {
        BackgroundTransClasses = 'BackgroundTransExited';
        LandingPageTransClasses = 'LandingPageTransExited';
        HeadersContainerTransClasses = 'HeadersContainerTransExited';
        ContentTransClasses = 'ContentTransExited';
    }
    else if (props.transitionStyle === 'exiting') {
        BackgroundTransClasses = 'BackgroundTransExiting';
        LandingPageTransClasses = 'LandingPageTransExiting';
        HeadersContainerTransClasses = 'HeadersContainerTransExiting';
        ContentTransClasses = 'ContentTransExiting';
    }
    // else if (props.transitionStyle === 'entered') {
    //     BackgroundTransClasses = 'BackgroundTransEntered';
    //     LandingPageTransClasses = 'LandingPageTransEntered';
    //     HeadersContainerTransClasses = 'HeadersContainerTransEntered';
    //     ContentTransClasses = 'ContentTransEntered';
    // }
    return (
        <div className={[Classes.LandingPage, Classes[LandingPageTransClasses]].join(" ")}>
            <div className={[Classes[ContentTransClasses]].join(" ")}>
                <div className={[Classes.HeadersContainer, Classes[HeadersContainerTransClasses]].join(" ")}>
                    <div className={[Classes.HeadingContainer, MarginBottomClasses.small].join(" ")}>
                        <img className={Classes.Logo} alt="logo" src={logo} />
                        <Heading1 extraClasses={Heading1Classes.LandingPage}>covers</Heading1>
                    </div>
                    <Heading2 extraClasses={Heading2Classes.LandingPage}>
                        Is where yachts
                    </Heading2>
                    <Heading2 extraClasses={Heading2Classes.LandingPage}>
                        get dressed
                    </Heading2>
                </div>
                <div className={Classes.ButtonBackgroundContainer}>
                    <ButtonPrimary onClick={onWelcomeHandler} title={ButtonSymbol} extraClasses={[ButtonPrimaryClasses.LandingPage].join(" ")} />
                    <div className={[Classes.Background, Classes[BackgroundTransClasses]].join(" ")}>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LandingPage;