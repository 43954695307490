import React from 'react';

import Classes from './LoginForm.module.scss';
import FormSmallInput from '../FormSmallInput/FormSmallInput';
import Heading3 from '../Headings/Heading3';
import ButtonSecondary from '../Buttons/ButtonSecondary';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser, logoutUser } from '../../store/actions/Authentication';
import { withRouter } from 'react-router';

const LoginForm = props => {
    const isAuth = useSelector(state => state.authentication.isAuth);
    const dispatch = useDispatch();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const [showError, setShowError] = useState(false);

    const checkcredentialsHandler = () => {
        if (username === 'xcovers' && password === '123') {
            dispatch(loginUser());
            props.onClick();
            props.location.pathname = '/home';
        } else {
            setShowError(true);
        }
    };

    let error;
    if (showError) {
        error = <h4 className={Classes.Error}>Wrong credentials</h4>;
    }

    const logoutHandler = () => {
        dispatch(logoutUser());
        props.onClick();
        props.location.pathname = '/home';
    }

    let loginOrLogout = (
        <div className={Classes.LoginFormModal} onClick={(e) => { e.stopPropagation() }}>
            <Heading3 extraClasses={Classes.Heading3}>Login</Heading3>
            <div className={Classes.UsernameContainer}>
                <FormSmallInput
                    extraClasses={Classes.Username}
                    placeholder="Username"
                    id="username"
                    onChange={e => setUsername(e.target.value)}
                    label="username"
                />
            </div>
            <div className={Classes.PasswordContainer}>
                <FormSmallInput
                    extraClasses={Classes.Password}
                    placeholder="Password"
                    id="password"
                    onChange={e => setPassword(e.target.value)}
                    label="password"
                />
            </div>
            <ButtonSecondary extraClasses={Classes.Btn} title="Enter" onClick={checkcredentialsHandler} />
            {error}
        </div>
    );

    if (isAuth) {
        loginOrLogout = (
            <ButtonSecondary extraClasses={Classes.BtnLogout} title="Logout" onClick={logoutHandler} />
        )
    }
    return (
        <div className={Classes.LoginFormBackdrop} onClick={props.onClick}>
            {loginOrLogout}
        </div>
    );
};

export default withRouter(LoginForm);