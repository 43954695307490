import React, { useState, useEffect, useRef } from 'react';

import { useSelector } from 'react-redux';

import { withRouter } from 'react-router-dom';
import Classes from '../../SASS/containers/Navigation.module.scss';
import NavButtonClasses from '../../SASS/components/Buttons/NavButton.module.scss';
import SVGIconClasses from '../../SASS/components/SVGIcon.module.scss';

import SVGIcon from '../../components/SVGIcon';
import NavButton from '../../components/Buttons/NavButton';
import TEXTS from '../../constants/TEXTS';


const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};


const Navigation = props => {
    const language = useSelector(state => state.language.language);
    const isAuth = useSelector(state => state.authentication.isAuth);
    const widthToHideNav = 'ClosedDrawer';
    const [navStyle, setNavStyle] = useState(null);
    const screenDimensions = useSelector(state => state.screenDimensions);


    const prevDimensions = usePrevious(screenDimensions);

    const toggleNavHandler = () => {
        setNavStyle(oldState => {
            if (oldState === null) {
                return widthToHideNav;
            } else {
                return null;
            }
        });
    };

    const navigateHandler = (path) => {
        props.history.push('/' + path);
    }
    useEffect(() => {
        if (prevDimensions) {
            if (Math.abs(prevDimensions.height - screenDimensions.height) > 100) {
                if (screenDimensions.width < 400) {
                    if (screenDimensions.height > screenDimensions.width) {
                        setNavStyle(null)
                    }
                }
            }
        } else {
            if (screenDimensions.width < 400) {
                if (screenDimensions.height > screenDimensions.width) {
                    setNavStyle(null)
                }
            }
        }
    }, [screenDimensions]);

    const navigationListItemsDetails = [
        { iconName: 'icon-home', pathname: 'home', title: language === 'EN' ? TEXTS.navigation.homeEN : TEXTS.navigation.homeGR },
        { iconName: 'icon-info', pathname: 'about', title: language === 'EN' ? TEXTS.navigation.aboutEN : TEXTS.navigation.aboutGR },
        { iconName: 'icon-briefcase', pathname: 'services', title: language === 'EN' ? TEXTS.navigation.servicesEN : TEXTS.navigation.servicesGR },
        { iconName: 'icon-images', pathname: isAuth ? 'galleryAuth' : 'gallery', title: language === 'EN' ? TEXTS.navigation.galleryEN : TEXTS.navigation.galleryGR },
        { iconName: 'icon-phone', pathname: 'contact', title: language === 'EN' ? TEXTS.navigation.contactEN : TEXTS.navigation.contactGR },
        { iconName: 'icon-thumbsup', pathname: 'feedback', title: language === 'EN' ? TEXTS.navigation.feedbackEN : TEXTS.navigation.feedbackGR },
    ];
    const greekFont = language === 'GR' && 'OnlyGreek';
    const navigationListItems = navigationListItemsDetails.map((item, idx) => {
        return (
            <li key={idx}
                className={[Classes.NavItem, Classes['/' + item.pathname === props.location.pathname.slice(0, item.pathname.length + 1) && 'Active']].join(" ")}
                onClick={navigateHandler.bind(this, item.pathname)}>
                <SVGIcon iconName={item.iconName} extraClasses={[SVGIconClasses.Navigation, SVGIconClasses[greekFont]].join(" ")} />
                <NavButton title={item.title} extraClasses={NavButtonClasses.Navigation} />
            </li>
        );
    });

    return (
        <nav className={[Classes.Navigation, Classes[navStyle]].join(" ")}>
            <div onClick={toggleNavHandler} className={Classes.MenuContainer}>
                <div className={Classes.Menu}></div>
            </div>
            <ul className={Classes.NavList}>
                {navigationListItems}
            </ul>
        </nav>
    );
};

export default withRouter(Navigation);