import React from 'react';

import Classes from '../SASS/components/ValidErrorModal.module.scss';
import ButtonSecondary from './Buttons/ButtonSecondary';

const ValidErrorModal = props => {
    return (
        <div className={Classes.ErrorModal} onClick={props.onCloseModal}>
            <div className={Classes.InnerContainer} onClick={(e) => { e.stopPropagation() }}>
                <h2 className={Classes.Error}>ERROR!</h2>
                <p className={Classes.Msg}>We found the following errors:</p>
                <div className={Classes.ErrorList}>
                    {props.errorList.map((error, id) => {
                        if (error === 'Enter a valid email address.') {
                            return <p key={id} className={Classes.errorText}>{error}</p>
                        }
                        return <p key={id} className={Classes.errorText}>{error} should not be empty!</p>
                    }
                    )}
                </div>
                <p className={Classes.Emoji}>&#128534;</p>
                <ButtonSecondary onClick={props.onCloseModal} extraClasses={Classes.Btn} title="Go back" />
            </div>
        </div>
    );
};

export default ValidErrorModal;